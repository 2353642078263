export const spineFile = [
  'anti',
  'banner_rs_end',
  'bg_basegame',
  'bg_fs',
  'buy_banner',
  'fs_lvup',
  'fs_lvup_window',
  'fs_title',
  'intro',
  'longspin',
  'message',
  'message_frame',
  'minipaytable',
  'mplebel',
  'mplevel',
  'openning',
  're_ice',
  'reelframe',
  'sc_prize',
  'snow_counter',
  'symbol_a',
  'symbol_b',
  'symbol_c',
  'symbol_d',
  'symbol_e',
  'symbol_f',
  'symbol_g',
  'symbol_sc1',
  'symbol_sc1_heart',
  'symbol_sc2',
  'symbol_wl',
  'title_logo',
  'win_line',
] as const;

export type SpineFile = (typeof spineFile)[number];

export interface SpineInterface {
  anti: { skins: 'default'; animations: '01' | '02' };
  banner_rs_end: { skins: 'default'; animations: 'base' | 'front' };
  bg_basegame: { skins: 'default'; animations: 'base' };
  bg_fs: { skins: 'default'; animations: 'a' | 'b' | 'c' };
  buy_banner: { skins: 'default'; animations: 'base' | 'betframe' };
  fs_lvup: {
    skins: 'default' | 'a' | 'b';
    animations:
      | '1_eff'
      | '1_in'
      | '1_loop'
      | '2_eff'
      | '2_in'
      | '2_loop'
      | '3_eff'
      | '3_in'
      | '3_loop'
      | '4_eff'
      | '4_in'
      | '4_loop'
      | '5_eff'
      | '5_in'
      | '5_loop';
  };
  fs_lvup_window: { skins: 'default'; animations: 'a' | 'b' };
  fs_title: { skins: 'default'; animations: 'base' };
  intro: { skins: 'default'; animations: 'page1' | 'page2' | 'page3' };
  longspin: {
    skins: 'default';
    animations:
      | 'bonus_l_back'
      | 'bonus_l_front'
      | 'bonus_m_back'
      | 'bonus_m_front'
      | 'bonus_s_back'
      | 'bonus_s_front'
      | 'snow_l_back'
      | 'snow_l_front'
      | 'snow_m_back'
      | 'snow_m_front'
      | 'snow_s_back'
      | 'snow_s_front';
  };
  message: { skins: 'default' | 'bigwin' | 'epicwin' | 'greatwin' | 'megawin'; animations: 'base' };
  message_frame: { skins: 'default'; animations: 'base' };
  minipaytable: { skins: 'default'; animations: 'base' };
  mplebel: { skins: 'default'; animations: 'base' | 'tate' };
  mplevel: {
    skins: 'default' | 'num_3' | 'num_5' | 'num_10' | 'num_30' | 'num_50';
    animations: 'num_wait' | 'num_win' | 'tate';
  };
  openning: { skins: 'default'; animations: 'base' };
  re_ice: { skins: 'default'; animations: 'in' | 'loop' | 'out' };
  reelframe: { skins: 'default'; animations: 'back' | 'base' };
  sc_prize: {
    skins: 'default';
    animations:
      | '3_a'
      | '3_b'
      | '4_a'
      | '4_b'
      | '5_a'
      | '5_b'
      | '6_a'
      | '6_b'
      | '7_a'
      | '7_b'
      | '8_a'
      | '8_b'
      | '9_a'
      | '9_b'
      | 'base';
  };
  snow_counter: {
    skins: 'default';
    animations:
      | 'frame_base'
      | 'frame_max'
      | 'snow_01_in'
      | 'snow_01_wait'
      | 'snow_02_in'
      | 'snow_02_wait'
      | 'snow_03_in'
      | 'snow_03_wait'
      | 'snow_04_in'
      | 'snow_04_wait'
      | 'snow_05_in'
      | 'snow_05_wait'
      | 'snow_06_in'
      | 'snow_06_wait'
      | 'snow_07_in'
      | 'snow_07_wait'
      | 'snow_08_in'
      | 'snow_08_wait'
      | 'snow_09_in'
      | 'snow_09_wait'
      | 'snow_max_wait';
  };
  symbol_a: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_b: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_c: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_d: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_e: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_f: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_g: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_sc1: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_sc1_heart: { skins: 'default'; animations: 'base' };
  symbol_sc2: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  symbol_wl: {
    skins: 'default';
    animations: 'longspin' | 'longspin_stop' | 'spin' | 'spining' | 'stop' | 'wait' | 'win';
  };
  title_logo: { skins: 'default'; animations: 'base' | 'intro_base' | 'intro_tate' | 'tate' };
  win_line: {
    skins: 'default';
    animations:
      | 'pos_01'
      | 'pos_02'
      | 'pos_03'
      | 'pos_04'
      | 'pos_05'
      | 'pos_06'
      | 'pos_07'
      | 'pos_08'
      | 'pos_09'
      | 'pos_10'
      | 'pos_11'
      | 'pos_12'
      | 'pos_13'
      | 'pos_14'
      | 'pos_15'
      | 'pos_16'
      | 'pos_17'
      | 'pos_18'
      | 'pos_19'
      | 'pos_20'
      | 'pos_21'
      | 'pos_22'
      | 'pos_23'
      | 'pos_24'
      | 'pos_25';
  };
}

export const ENABLE_MERGED_ATRAS = true;

export const preloadSpineTextures = [
  'snow_alpha-0.png',
  'snow_alpha-1.png',
  'snow_bw-0.png',
  'snow_no_alpha-0.jpg',
  'snow_no_alpha-1.jpg',
  'snow_no_alpha-2.jpg',
];
