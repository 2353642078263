export enum SlotId {
  SC1 = 'SC1',
  SC2 = 'SC2',
  WL = 'WL',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum PayTableSlotId {
  SC1 = 'SC1',
  SC2 = 'SC2',
  WL = 'WL',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  Any7 = 'H',
}

export const config = {
  failureRetries: 5,
  autoplay: {
    defaultAutoSpinsAmount: 100,
    timeOut: 1000,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'Game ID',
    },
  },
  payTableData: [
    SlotId.SC1,
    SlotId.WL,
    SlotId.SC2,
    SlotId.A,
    SlotId.B,
    SlotId.C,
    PayTableSlotId.Any7,
    SlotId.D,
    SlotId.E,
    SlotId.F,
    SlotId.G,
  ],
} as const;
