/* eslint-disable no-fallthrough */
import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { SlotId } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { setCoinAmount, setCurrency, setGameMode, setSlotConfig } from '../../gql/cache';
import { getCombosDataFromIcon, getNonNullableValue, isRespinMode, normalizeCoins, showCurrency } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerReelFront } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';
import { Combos, IconCombo } from '../d';

import {
  SNOW_PRIZE_LAYOUT_TBL,
  SNOW_PRIZE_POSITION_PORTRAIT_X,
  SNOW_PRIZE_POSITION_PORTRAIT_Y,
  SNOW_PRIZE_POSITION_X,
  SNOW_PRIZE_POSITION_Y,
  SnowPrizeComboType,
} from './config';

class SnowPrize extends ViewContainer {
  private prizeObj: TickerSpine<'sc_prize'>;

  private currency = '';

  public combos: Combos = [];

  public multipliersValues!: Record<SnowPrizeComboType, AutoResizeText>;

  private snowCount = 0;
  constructor() {
    super();

    this.prizeObj = new TickerSpine('sc_prize');
    this.addChild(this.prizeObj);

    this.prizeObj.state.setAnimation(0, 'base', true);

    this.initMultipliersValue();
    this.currency = setCurrency();
    this.setPayTableData();
    this.initSubscriptions();
    this.parentLayer = layerReelFront;
  }

  private initSubscriptions = (): void => {
    eventManager.addListener(EventTypes.UPDATE_BET, () => this.handleChangeBetAmount());
    eventManager.addListener(EventTypes.SET_SNOW_PRIZE_SNOW_COUNT, this.setSnowCount.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.resetSnowCount.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  };
  private onModeChange(_settings: { mode: GameMode }) {}
  private initMultipliersValue = (): void => {
    this.multipliersValues = {} as Record<SnowPrizeComboType, AutoResizeText>;

    Object.entries(SNOW_PRIZE_LAYOUT_TBL).forEach(([comboType, layoutInfo]) => {
      const multipliersValue = new AutoResizeText('', {
        ...layoutInfo.style,
      });
      if (PIXI.utils.isMobile.phone) {
        multipliersValue.resolution = 1;
      }
      multipliersValue.anchor.set(1, 0.5);
      multipliersValue.x = layoutInfo.position.x;
      multipliersValue.y = layoutInfo.position.y;

      this.addChild(multipliersValue);
      this.multipliersValues[comboType as SnowPrizeComboType] = multipliersValue;
    });
  };
  private calcMultiplier(multiplier: number): number {
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  private getCombosText(): { combo: SnowPrizeComboType; text: string }[] {
    return this.combos.map((curr: IconCombo) => {
      return {
        combo: curr.pattern as SnowPrizeComboType,
        text: `${formatNumber({
          currency: this.currency,
          value: this.calcMultiplier(curr.multiplier * setSlotConfig().lineSet.coinAmountMultiplier),
          showCurrency: showCurrency(this.currency),
        })}`,
      };
    });
  }

  public setPayTableData(): void {
    const icon = getNonNullableValue(
      setSlotConfig().icons?.find((icon) => {
        return icon?.id === SlotId.SC1;
      }),
    );
    this.combos = getCombosDataFromIcon(icon);
    const multiplierTexts = this.getCombosText();
    multiplierTexts.forEach(({ combo, text }) => {
      if (this.multipliersValues[combo]) {
        this.multipliersValues[combo].text = text;
      }
    });
  }
  private handleChangeBetAmount(): void {
    this.setPayTableData();
    this.updatePayTableData();
  }
  private updatePayTableData(): void {}

  private updateSnowFrame(): void {
    if (this.snowCount >= 3 && this.snowCount <= 9) {
      const animationName = `${this.snowCount}_a` as SpineInterface['sc_prize']['animations'];
      this.prizeObj.state.setAnimation(0, animationName, true);
    } else {
      this.prizeObj.state.setAnimation(0, 'base', true);
    }
    this.updateMultiplierTint(this.snowCount);
  }

  private updateMultiplierTint(nowSnowCount: number) {
    const CONV_NUMBER_TO_COMBOTYPE: SnowPrizeComboType[] = ['x3', 'x3', 'x3', 'x3', 'x4', 'x5', 'x6', 'x7', 'x8', 'x9'];
    if (nowSnowCount >= 4 && nowSnowCount <= 9) {
      for (let i = nowSnowCount; i >= 4; i--) {
        this.multipliersValues[CONV_NUMBER_TO_COMBOTYPE[i - 1]!].tint = 0x666666;
      }
    } else if (nowSnowCount >= 0 && nowSnowCount <= 3) {
      for (let i = 0; i < CONV_NUMBER_TO_COMBOTYPE.length; i++) {
        this.multipliersValues[CONV_NUMBER_TO_COMBOTYPE[i]!].tint = 0xffffff;
      }
    }
  }

  private setSnowCount(snowCount: number): void {
    this.snowCount += snowCount;
    this.updateSnowFrame();
  }
  private resetSnowCount(): void {
    this.snowCount = isRespinMode(setGameMode()) ? 3 : 0;
    this.updateSnowFrame();
  }

  private onChangeMode(settings: { mode: GameMode }) {
    if (settings.mode === GameMode.REGULAR) {
      this.resetSnowCount();
    }
  }

  private startWinAnimation(): void {
    if (this.snowCount >= 3 && this.snowCount <= 9) {
      const animationName = `${this.snowCount}_b` as SpineInterface['sc_prize']['animations'];
      this.prizeObj.state.setAnimation(0, animationName, true);
    }
  }
  //TO DO
  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.x = SNOW_PRIZE_POSITION_X;
      this.y = SNOW_PRIZE_POSITION_Y;
    } else {
      this.x = SNOW_PRIZE_POSITION_PORTRAIT_X;
      this.y = SNOW_PRIZE_POSITION_PORTRAIT_Y;
    }
  };
}

export default SnowPrize;
