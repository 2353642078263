import ViewContainer from '../components/container';
import { layerWin } from '../components/layers/layers';
import {
  EventTypes,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  eventManager,
} from '../config';

class SafeArea extends ViewContainer {
  constructor() {
    super();
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    this.parentLayer = layerWin;
    //layerWin
  }

  private calculateSize(
    width: number,
    height: number,
  ): [newWidth: number, newHeight: number, scale: number, pivotX: number, pivotY: number] {
    let newWidth = 0;
    let newHeight = 0;

    const isLandscape = width / height >= 1;

    const ratio = isLandscape
      ? SAFE_AREA_LANDSCAPE_WIDTH / SAFE_AREA_LANDSCAPE_HEIGHT
      : SAFE_AREA_PORTRAIT_WIDTH / SAFE_AREA_PORTRAIT_HEIGHT;

    const isRectangleRatio = +(width / height).toFixed(2) >= +ratio.toFixed(2);
    if (isRectangleRatio) {
      newWidth = height * ratio;
      newHeight = height;
    } else {
      newWidth = width;
      newHeight = width / ratio;
    }

    const pivotX = isLandscape ? SAFE_AREA_LANDSCAPE_PIVOT_X : SAFE_AREA_PORTRAIT_PIVOT_X;
    const pivotY = isLandscape ? SAFE_AREA_LANDSCAPE_PIVOT_Y : SAFE_AREA_PORTRAIT_PIVOT_Y;
    const scale = isLandscape ? newWidth / SAFE_AREA_LANDSCAPE_WIDTH : newWidth / SAFE_AREA_PORTRAIT_WIDTH;

    return [newWidth, newHeight, scale, pivotX, pivotY];
  }

  private resize(width: number, height: number): void {
    const [newWidth, newHeight, scale, pivotX, pivotY] = this.calculateSize(width, height);

    this.position.set(Math.max((width - newWidth) / 2, 0), Math.max((height - newHeight) / 2, 0));
    this.pivot.set(pivotX, pivotY);
    this.scale.set(scale);

    eventManager.emit(EventTypes.RESIZE_GAME_CONTAINER, width, height, scale);
  }
}
export default SafeArea;
