import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { layerAnnounce } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import { Icon } from '../d';

import { AnnounceType } from './config';

export class AnnounceContainer extends ViewContainer {
  private spine: TickerSpine<'anti'>;
  constructor(_spinResult: Icon[]) {
    super();

    this.spine = new TickerSpine<'anti'>('anti');
    this.spine.update(0);
    this.spine.state.setEmptyAnimation(0);
    this.spine.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    this.addChild(this.spine);
    this.visible = false;

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.START_ANNOUNCEMENT, this.startAnnounceAnimation.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.reelStopped.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {});

    this.parentLayer = layerAnnounce;
  }

  private startAnnounceAnimation(announceType: AnnounceType) {
    switch (announceType) {
      default:
      case 'None':
      case 'phoenix':
        break;
      case 'low': {
        this.spine.state.setAnimation(0, '01', false);
        this.spine.state.addEmptyAnimation(0);
        this.visible = true;

        const delay = Tween.createDelayAnimation(this.spine.getAnimation(0, '01').duration * 1000);
        delay.addOnComplete(() => {
          this.visible = false;
        });
        delay.start();
        AudioApi.play({ type: ISongs.XT004S_anticipation_low, stopPrev: true });

        break;
      }
      case 'high': {
        this.spine.state.setAnimation(0, '02', false);
        this.spine.state.addEmptyAnimation(0);
        this.visible = true;

        const delay = Tween.createDelayAnimation(this.spine.getAnimation(0, '02').duration * 1000);
        delay.addOnComplete(() => {
          this.visible = false;
        });
        delay.start();
        AudioApi.play({ type: ISongs.XT004S_anticipation_high, stopPrev: true });

        break;
      }
    }
  }

  private reelStopped(): void {
    this.visible = false;
  }

  private hideContainer(): void {
    this.visible = false;
  }
}
