import AnimationChain from '../animations/animationChain';
import { ISpinAnimation } from '../animations/d';
import Tween from '../animations/tween';
import { EventTypes, eventManager } from '../config';

class SpinAnimation extends AnimationChain {
  constructor(options: ISpinAnimation) {
    super(options);
    this.appendAnimation(options.startingDelayAnimation);
    this.appendAnimation(options.startingAnimation);
    this.appendAnimation(options.firstRollingAnimation);
    this.appendAnimation(options.fakeRollingAnimation);
    this.appendAnimation(options.rollingAnimation);
    this.appendAnimation(options.endingAnimation);
    eventManager.addListener(EventTypes.BREAK_SPIN_ANIMATION, this.breakSpinAnimation.bind(this));
  }

  public getStartingDelay(): Tween {
    return this.animations[0] as Tween;
  }

  public getStarting(): Tween {
    return this.animations[1] as Tween;
  }

  public getFirstRolling(): Tween {
    return this.animations[2] as Tween;
  }

  public getFakeRolling(): Tween {
    return this.animations[3] as Tween;
  }

  public getRolling(): Tween {
    return this.animations[4] as Tween;
  }

  public getEnding(): Tween {
    return this.animations[5] as Tween;
  }

  public setStarting(tween: Tween): void {
    this.animations[1] = tween;
  }

  public setFirstRolling(tween: Tween): void {
    this.animations[2] = tween;
  }

  public setFakeRolling(tween: Tween): void {
    this.animations[3] = tween;
  }

  public setRolling(tween: Tween): void {
    this.animations[4] = tween;
  }

  public setEnding(tween: Tween): void {
    this.animations[5] = tween;
  }

  private breakSpinAnimation(): void {
    this.animations.forEach((animation) => eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, animation as Tween));
  }
}

export default SpinAnimation;
