import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SFX_UI_BetChange: 'SFX_UI_BetChange',
  SFX_UI_Close: 'SFX_UI_Close',
  SFX_UI_General: 'SFX_UI_General',
  SFX_UI_Hover: 'SFX_UI_Hover',
  SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  XT004S_SC1_longspin_long: 'XT004S_SC1_longspin_long',
  XT004S_SC1_longspin_middle: 'XT004S_SC1_longspin_middle',
  XT004S_SC1_longspin_short: 'XT004S_SC1_longspin_short',
  XT004S_SC1_stop: 'XT004S_SC1_stop',
  XT004S_SC1_triple_stop: 'XT004S_SC1_triple_stop',
  XT004S_SC2_longspin_long: 'XT004S_SC2_longspin_long',
  XT004S_SC2_longspin_middle: 'XT004S_SC2_longspin_middle',
  XT004S_SC2_longspin_short: 'XT004S_SC2_longspin_short',
  XT004S_SC2_stop1: 'XT004S_SC2_stop1',
  XT004S_SC2_stop2: 'XT004S_SC2_stop2',
  XT004S_SC2_stop3: 'XT004S_SC2_stop3',
  XT004S_additional_spin_feature1: 'XT004S_additional_spin_feature1',
  XT004S_additional_spin_feature2: 'XT004S_additional_spin_feature2',
  XT004S_additional_spin_feature3: 'XT004S_additional_spin_feature3',
  XT004S_addtional_spin: 'XT004S_addtional_spin',
  XT004S_anticipation_high: 'XT004S_anticipation_high',
  XT004S_anticipation_low: 'XT004S_anticipation_low',
  XT004S_bg_base_loop: 'XT004S_bg_base_loop',
  XT004S_bg_feature1_loop: 'XT004S_bg_feature1_loop',
  XT004S_bg_feature2_loop: 'XT004S_bg_feature2_loop',
  XT004S_bg_feature3_loop: 'XT004S_bg_feature3_loop',
  XT004S_bigwin_1shot: 'XT004S_bigwin_1shot',
  XT004S_feature_end_high: 'XT004S_feature_end_high',
  XT004S_feature_end_low: 'XT004S_feature_end_low',
  XT004S_feature_trigger: 'XT004S_feature_trigger',
  XT004S_freeze: 'XT004S_freeze',
  XT004S_freeze_break: 'XT004S_freeze_break',
  XT004S_longspin: 'XT004S_longspin',
  XT004S_phoenix_sign: 'XT004S_phoenix_sign',
  XT004S_push_buy_feature: 'XT004S_push_buy_feature',
  XT004S_respin: 'XT004S_respin',
  XT004S_snow_add_1: 'XT004S_snow_add_1',
  XT004S_snow_add_2: 'XT004S_snow_add_2',
  XT004S_snow_add_3: 'XT004S_snow_add_3',
  XT004S_snow_add_4: 'XT004S_snow_add_4',
  XT004S_snow_add_5: 'XT004S_snow_add_5',
  XT004S_snow_add_6: 'XT004S_snow_add_6',
  XT004S_snow_add_7: 'XT004S_snow_add_7',
  XT004S_snow_add_8: 'XT004S_snow_add_8',
  XT004S_snow_max: 'XT004S_snow_max',
  XT004S_snow_particle: 'XT004S_snow_particle',
  XT004S_spin_loop: 'XT004S_spin_loop',
  XT004S_spin_start: 'XT004S_spin_start',
  XT004S_spin_stop1: 'XT004S_spin_stop1',
  XT004S_spin_stop2: 'XT004S_spin_stop2',
  XT004S_spin_stop3: 'XT004S_spin_stop3',
  XT004S_spin_stop4: 'XT004S_spin_stop4',
  XT004S_spin_stop5: 'XT004S_spin_stop5',
  XT004S_win_big: 'XT004S_win_big',
  XT004S_win_end: 'XT004S_win_end',
  XT004S_win_epic: 'XT004S_win_epic',
  XT004S_win_great: 'XT004S_win_great',
  XT004S_win_loop: 'XT004S_win_loop',
  XT004S_win_mega: 'XT004S_win_mega',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: TAudioSprite = {
  SFX_UI_AutoSpin: [0, 320.4761904761905],
  SFX_UI_BetChange: [2000, 32.086167800453595],
  SFX_UI_Close: [4000, 200.6802721088432],
  SFX_UI_General: [6000, 88.34467120181432],
  SFX_UI_Hover: [8000, 151.76870748299365],
  SFX_UI_MaxBet: [10000, 122.92517006802761],
  SFX_UI_MenuOpen: [12000, 277.14285714285757],
  XT004S_SC1_longspin_long: [14000, 3301.020408163264],
  XT004S_SC1_longspin_middle: [19000, 2766.6893424036266],
  XT004S_SC1_longspin_short: [23000, 2266.6666666666656],
  XT004S_SC1_stop: [27000, 1666.6666666666679],
  XT004S_SC1_triple_stop: [30000, 2433.33333333333],
  XT004S_SC2_longspin_long: [34000, 7766.666666666666],
  XT004S_SC2_longspin_middle: [43000, 6066.666666666662],
  XT004S_SC2_longspin_short: [51000, 4100.000000000002],
  XT004S_SC2_stop1: [57000, 3166.689342403629],
  XT004S_SC2_stop2: [62000, 2366.66666666666],
  XT004S_SC2_stop3: [66000, 2366.66666666666],
  XT004S_additional_spin_feature1: [70000, 2714.353741496595],
  XT004S_additional_spin_feature2: [74000, 2714.353741496595],
  XT004S_additional_spin_feature3: [78000, 6142.925170068025],
  XT004S_addtional_spin: [86000, 5261.90476190476],
  XT004S_anticipation_high: [93000, 3633.33333333334],
  XT004S_anticipation_low: [98000, 4000],
  XT004S_bg_base_loop: [103000, 52002.01814058957, true],
  XT004S_bg_feature1_loop: [157000, 27428.61678004536, true],
  XT004S_bg_feature2_loop: [186000, 24000.045351473917, true],
  XT004S_bg_feature3_loop: [212000, 30857.188208616775, true],
  XT004S_bigwin_1shot: [244000, 3428.6167800453595],
  XT004S_feature_end_high: [249000, 7295.623582766439],
  XT004S_feature_end_low: [258000, 6857.165532879833],
  XT004S_feature_trigger: [266000, 4233.3333333333485],
  XT004S_freeze: [272000, 2366.6893424036175],
  XT004S_freeze_break: [276000, 1433.3560090702804],
  XT004S_longspin: [279000, 4000.0453514739434],
  XT004S_phoenix_sign: [285000, 4033.33333333336],
  XT004S_push_buy_feature: [291000, 366.66666666667425],
  XT004S_respin: [293000, 1763.0158730158882],
  XT004S_snow_add_1: [296000, 2166.6666666666856],
  XT004S_snow_add_2: [300000, 2166.689342403629],
  XT004S_snow_add_3: [304000, 2166.6666666666856],
  XT004S_snow_add_4: [308000, 2166.6666666666856],
  XT004S_snow_add_5: [312000, 2166.6666666666856],
  XT004S_snow_add_6: [316000, 2166.6666666666856],
  XT004S_snow_add_7: [320000, 2166.6666666666856],
  XT004S_snow_add_8: [324000, 2166.6666666666856],
  XT004S_snow_max: [328000, 3366.6893424036175],
  XT004S_snow_particle: [333000, 1966.6666666666401],
  XT004S_spin_loop: [336000, 3614.8072562358493, true],
  XT004S_spin_start: [341000, 1333.3560090703145],
  XT004S_spin_stop1: [344000, 766.6666666666515],
  XT004S_spin_stop2: [346000, 833.3333333333144],
  XT004S_spin_stop3: [348000, 733.3333333333485],
  XT004S_spin_stop4: [350000, 766.6666666666515],
  XT004S_spin_stop5: [352000, 733.3333333333485],
  XT004S_win_big: [354000, 1800.068027210898],
  XT004S_win_end: [357000, 1000],
  XT004S_win_epic: [359000, 3000.0453514739434],
  XT004S_win_great: [364000, 1800.068027210898],
  XT004S_win_loop: [367000, 1000, true],
  XT004S_win_mega: [369000, 1800.068027210898],
};
