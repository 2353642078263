import { Colors, Variables } from '../../config';
import { AutoResizeTextStyle } from '../components/autoResizeText';

const font = Variables.FONT_FAMILY;

// Button Text Style
export const buyFeatureBtnTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  fontSize: 58,
  lineHeight: 58,
  maxWidth: 188,
  maxHeight: 84,
  fill: Colors.GAME_COLOR,
  letterSpacing: 0,
  lineJoin: 'round',
};

export const buyFeatureTitleStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 70,
  fontFamily: font,
  maxWidth: 620,
  fill: '#001950',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 10,
  lineJoin: 'round',
};

export const buyFeatureBetDescriptionTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 56,
  fontFamily: font,
  maxWidth: 700,
  fill: '#001950',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const totalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 45,
  fontFamily: font,
  letterSpacing: 0,
  align: 'left',
  maxWidth: 700,
  fill: '#001950',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const totalCostTextAmountStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 97,
  fontFamily: font,
  fill: '#FE7800',
  letterSpacing: 0,
  fontWeight: '700',
  align: 'right',
  stroke: '#ffffff',
  strokeThickness: 10,
  maxWidth: 900,
  lineJoin: 'round',
};

export const betValueStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 71,
  fontFamily: font,
  fill: '#FFFFFF',
  letterSpacing: 0,
  fontWeight: '700',
  align: 'center',
  maxWidth: 384,
  stroke: '#000000',
  strokeThickness: 2,
  lineJoin: 'round',
};

export const buyFeatureConfirmTitleStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 49,
  fontFamily: font,
  fill: '#001950',
  fontWeight: '700',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 8,
  maxWidth: 680,
  lineJoin: 'round',
  lineHeight: 50,
};
export const confirmTotalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 61,
  fontFamily: font,
  letterSpacing: 0,
  align: 'left',
  maxWidth: 680,
  fill: '#001950',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
};
