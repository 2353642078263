import { IDestroyOptions } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { setIsSoundOn } from '../../gql/cache';
import { UiButton } from '../components/ui/uiButton';
import { EventTypes, eventManager } from '../config';

export class SoundBtn extends UiButton {
  constructor() {
    super(setIsSoundOn() ? 'sound-on' : 'sound-off');
    this.addChild(this.btn);
    this.interactive = true;
    this.initEvents();
    this.initSubscriptions();

    if (!AudioApi.isInitialized) {
      this.setDisable(true);
    }
  }

  private initEvents(): void {
    this.btn.on('click', () => this.handleClick());
    this.btn.on('touchstart', () => this.handleClick());
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.TOGGLE_SOUND, (isSoundOn: boolean) => {
      this.updateIntent(isSoundOn ? 'sound-on' : 'sound-off');
    });

    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      this.setDisable(false);
    });
  }

  private handleClick(): void {
    const nextState = !setIsSoundOn();
    AudioApi.unSuspend();
    AudioApi.setSoundState(nextState);
    AudioApi.processRestriction({
      restricted: false,
    });
    setIsSoundOn(!setIsSoundOn());
    this.updateIntent(setIsSoundOn() ? 'sound-on' : 'sound-off');
  }

  public override destroy(options?: boolean | IDestroyOptions): void {
    eventManager.removeListener(EventTypes.TOGGLE_SOUND);
    eventManager.removeListener(EventTypes.SOUND_INITIALIZED);
    super.destroy(options);
  }
}
