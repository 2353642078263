export const REELS_Z_ORDER_REEL = [1, 2, 3, 2, 1];
export const REELS_Z_ORDER_REEL_SC = [5, 6, 7, 6, 5];

export const REELS_Z_ORDER_STOP_SLOTS = [1, 2, 3, 2, 1];
export const REELS_Z_ORDER_STOP_SLOTS_SC = [5, 6, 7, 6, 5];

export const REELS_Z_ORDER_HIGHLIGHT_SLOT = [11, 12, 13, 12, 11];

export const REELS_Z_ORDER_WIN_SLOTS = [12, 13, 14, 13, 12];
export const REELS_Z_ORDER_WIN_SLOTS_SC = [12, 15, 16, 15, 12];

export const REELS_Z_ORDER_TINT_CONTAINER = 10;

export const REEL_LINE_Z_ORDER = 11;
