export enum ResourceTypes {
  infoPaylines = 'infoPaylines',
  textPatternBannerRsEndLimitA = 'textPatternBannerRsEndLimitA',
  logo = 'logo',
  symbolA = 'symbolA',
  symbolABlur = 'symbolABlur',
  symbolAStop = 'symbolAStop',
  symbolAny7 = 'symbolAny7',
  symbolB = 'symbolB',
  symbolBBlur = 'symbolBBlur',
  symbolBStop = 'symbolBStop',
  symbolC = 'symbolC',
  symbolCBlur = 'symbolCBlur',
  symbolCStop = 'symbolCStop',
  symbolD = 'symbolD',
  symbolDBlur = 'symbolDBlur',
  symbolDStop = 'symbolDStop',
  symbolE = 'symbolE',
  symbolEBlur = 'symbolEBlur',
  symbolEStop = 'symbolEStop',
  symbolF = 'symbolF',
  symbolFBlur = 'symbolFBlur',
  symbolFStop = 'symbolFStop',
  symbolG = 'symbolG',
  symbolGBlur = 'symbolGBlur',
  symbolGStop = 'symbolGStop',
  symbolSc1 = 'symbolSc1',
  symbolSc2 = 'symbolSc2',
  symbolWl = 'symbolWl',
  symbolWlBlur = 'symbolWlBlur',
}
