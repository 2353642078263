import * as PIXI from 'pixi.js';

import { SpineInterface } from '../../config/spine.generated';
import { MessageLevelUpBannerProps } from '../../global';
import i18n from '../../i18next';
import AutoResizeText from '../components/autoResizeText';
import { layerBanner } from '../components/layers/layers';
import { TickerSpine } from '../components/spine/index';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';
import { SNOW_LEVEL_MULTIPLIER } from '../snow/config';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  snowLevelUpLineWinNextTextStyle,
  snowLevelUpLineWinPreTextStyle,
  snowLevelUpRoundAddedNumOnlyTextStyle,
  snowLevelUpRoundAddedNumTextStyle,
  snowLevelUpRoundAddedOnlyTextStyle,
  snowLevelUpRoundAddedTextStyle,
  snowLevelUpTitleTextStyle,
} from './textStyles';

type LevelUpType = '1to2' | '2to3' | '3to4' | '4to5' | 'Round';
const numberToLvUpType: LevelUpType[] = ['1to2', '2to3', '3to4', '4to5', 'Round'];

interface LevelUpInfoType {
  basePosition: PIXI.IPointData;
  inAnim: SpineInterface['fs_lvup']['animations'];
  loopAnim: SpineInterface['fs_lvup']['animations'];
  windowAnim: SpineInterface['fs_lvup_window']['animations'];
  effectAnim: SpineInterface['fs_lvup']['animations'];
}
const LevelUpInfo: Record<LevelUpType, LevelUpInfoType> = {
  '1to2': {
    basePosition: { x: -291, y: -142 },
    inAnim: '1_in',
    loopAnim: '1_loop',
    windowAnim: 'a',
    effectAnim: '1_eff',
  },
  '2to3': {
    basePosition: { x: 327, y: 137 },
    inAnim: '2_in',
    loopAnim: '2_loop',
    windowAnim: 'a',
    effectAnim: '2_eff',
  },
  '3to4': {
    basePosition: { x: 326, y: 137 },
    inAnim: '3_in',
    loopAnim: '3_loop',
    windowAnim: 'a',
    effectAnim: '3_eff',
  },
  '4to5': {
    basePosition: { x: -339, y: -181 },
    inAnim: '4_in',
    loopAnim: '4_loop',
    windowAnim: 'a',
    effectAnim: '4_eff',
  },
  'Round': {
    basePosition: { x: 287, y: -203 },
    inAnim: '5_in',
    loopAnim: '5_loop',
    windowAnim: 'b',
    effectAnim: '5_eff',
  },
};

export class messageLevelUpBanner extends BaseMessageBanner {
  protected override props: MessageLevelUpBannerProps;

  private textBase!: TickerSpine<'fs_lvup_window'>;
  private girl!: TickerSpine<'fs_lvup'>;

  private effect!: TickerSpine<'fs_lvup'>;

  private textlevelUp!: AutoResizeText;
  private textLineWin!: AutoResizeText;
  private textLineWinNext!: AutoResizeText;
  private textRoundAdd!: AutoResizeText;

  private textRoundAddNum!: AutoResizeText;
  private textRoundAddNumOnly!: AutoResizeText;
  private textRoundAddOnly!: AutoResizeText;

  private lvContainer!: PIXI.Container;
  constructor(props: MessageLevelUpBannerProps) {
    super(props);
    this.props = props;

    this.initBanner(this.props.isLevelUp, this.props.snowLevel, this.props.snowCount >= 11);
  }

  public override init(): messageLevelUpBanner {
    super.init();
    return this;
  }

  private initBanner = (isLevelUp: boolean, level: number, isOverCount: boolean) => {
    const type = isLevelUp ? numberToLvUpType[level]! : 'Round';
    const info = LevelUpInfo[type];

    this.girl = new TickerSpine<'fs_lvup'>('fs_lvup');
    this.girl.update(0);
    this.girl.state.setAnimation(0, info.inAnim);
    this.girl.state.addAnimation(0, info.loopAnim, true);
    this.girl.skeleton.setSkinByName(isOverCount ? 'b' : 'a');

    this.effect = new TickerSpine<'fs_lvup'>('fs_lvup');
    this.effect.update(0);
    this.effect.state.setAnimation(0, info.effectAnim, true);

    this.girl.parentLayer = layerBanner;
    this.effect.parentLayer = layerBanner;
    this.girl.zOrder = 5;
    this.effect.zOrder = 12;

    this.textBase = new TickerSpine<'fs_lvup_window'>('fs_lvup_window');
    this.textBase.update(0);
    this.textBase.state.setAnimation(0, info.windowAnim, true);
    this.lvContainer = new PIXI.Container();
    if (isLevelUp) {
      this.initLvUpContainer();
    } else {
      this.initRoundAddContainer();
    }
    this.lvContainer.position.copyFrom(info.basePosition);

    this.addChild(this.girl);
    this.addChild(this.lvContainer);
    this.addChild(this.effect);
  };

  private initLvUpContainer() {
    const pre = SNOW_LEVEL_MULTIPLIER[this.props.snowLevel > 0 ? this.props.snowLevel : 0];
    const next = SNOW_LEVEL_MULTIPLIER[this.props.snowLevel + 1];

    this.textlevelUp = new AutoResizeText(i18n.t('snowLevelUpBanner.levelUp'), {
      ...snowLevelUpTitleTextStyle,
    });
    this.textlevelUp.y = -58;
    this.textlevelUp.anchor.set(0.5, 0.5);

    this.textLineWin = new AutoResizeText(i18n.t('snowLevelUpBanner.lineWin', { pre: pre }), {
      ...snowLevelUpLineWinPreTextStyle,
    });
    this.textLineWin.x = 116;
    this.textLineWin.y = 15;
    this.textLineWin.anchor.set(1, 0.5);

    this.textLineWinNext = new AutoResizeText(i18n.t('snowLevelUpBanner.lineWinNext', { next: next }), {
      ...snowLevelUpLineWinNextTextStyle,
    });
    this.textLineWinNext.x = 116;
    this.textLineWinNext.y = 12;
    this.textLineWinNext.anchor.set(0, 0.5);

    this.textRoundAddNum = new AutoResizeText('+6', snowLevelUpRoundAddedNumTextStyle);
    this.textRoundAddNum.x = -66;
    this.textRoundAddNum.y = 74;
    this.textRoundAddNum.anchor.set(1, 0.5);

    this.textRoundAdd = new AutoResizeText(i18n.t('snowLevelUpBanner.RoundAdded'), {
      ...snowLevelUpRoundAddedTextStyle,
    });
    this.textRoundAdd.x = -66;
    this.textRoundAdd.y = 74;
    this.textRoundAdd.anchor.set(0, 0.5);

    this.textBase.parentLayer = layerBanner;
    this.textlevelUp.parentLayer = layerBanner;
    this.textLineWin.parentLayer = layerBanner;
    this.textLineWinNext.parentLayer = layerBanner;
    this.textRoundAdd.parentLayer = layerBanner;
    this.textRoundAddNum.parentLayer = layerBanner;
    this.textBase.zOrder = 10;
    this.textlevelUp.zOrder = 15;
    this.textLineWin.zOrder = 15;
    this.textLineWinNext.zOrder = 15;
    this.textRoundAdd.zOrder = 15;
    this.textRoundAddNum.zOrder = 15;

    this.lvContainer.addChild(
      this.textBase,
      this.textlevelUp,
      this.textLineWin,
      this.textLineWinNext,
      this.textRoundAdd,
      this.textRoundAddNum,
    );
  }

  private initRoundAddContainer() {
    this.textRoundAddNumOnly = new AutoResizeText('+6', snowLevelUpRoundAddedNumOnlyTextStyle);
    this.textRoundAddNumOnly.y = -52;
    this.textRoundAddNumOnly.anchor.set(0.5, 0.5);

    this.textRoundAddOnly = new AutoResizeText(i18n.t('snowLevelUpBanner.RoundAdded'), {
      ...snowLevelUpRoundAddedOnlyTextStyle,
    });
    this.textRoundAddOnly.y = 29;
    this.textRoundAddOnly.anchor.set(0.5, 0.5);

    this.textBase.parentLayer = layerBanner;
    this.textRoundAddNumOnly.parentLayer = layerBanner;
    this.textRoundAddOnly.parentLayer = layerBanner;
    this.textBase.zOrder = 10;
    this.textRoundAddNumOnly.zOrder = 15;
    this.textRoundAddOnly.zOrder = 15;

    this.lvContainer.addChild(this.textBase, this.textRoundAddOnly, this.textRoundAddNumOnly);
  }

  public override handleResize(width: number, height: number): void {
    super.handleResize(width, height);
    if (height > width) {
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;
    } else {
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;
    }
  }
}
