import { SlotId } from '../config';
import { GameMode } from '../consts';
import { ISettledBet } from '../global';
import { ClientSlotFeatureSettings } from '../gql';
import {
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinValue,
  setCurrentBonus,
  setIsAutoSpins,
  setIsBuyFeatureSpin,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
} from '../gql/cache';
import { BonusKind } from '../slotMachine/config/bonusInfo';

import { urlSearchParams } from './helper';
import { getBonusKind, getFreeSpinBonus } from './slotMachine';

export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR || mode === GameMode.BUY_FEATURE;
};
export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS || isRespinMode(mode);
};

export const isFreeSpinsBaseMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isFreeSpinsGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS || mode === GameMode.FREE_SPINS_RESPIN;
};

export const isRespinMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR_RESPIN || mode === GameMode.FREE_SPINS_RESPIN;
};
export const isScatter = (slotId: SlotId): boolean => {
  if (slotId === SlotId.SC2) {
    return true;
  }
  return false;
};

export const isBuyFeatureMode = (): boolean => {
  return setIsBuyFeatureSpin();
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isOpenedMessageBanner,
  isInTransition,
  isBuyFeaturePopupOpened,
  isOpeningScenePlaying,
  isSpinShortCut,
  isAutoPlay,
  isSnowCorrect,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isInTransition: boolean;
  isBuyFeaturePopupOpened: boolean;
  isOpeningScenePlaying: boolean;
  isSpinShortCut: boolean;
  isAutoPlay: boolean;
  isSnowCorrect: boolean;
}): boolean => {
  if (isInTransition) {
    return false;
  }
  if (isAutoPlay) {
    return false;
  }
  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }
  if (isFreeSpinsMode(gameMode) && isOpenedMessageBanner) {
    return true;
  }
  if (isFreeSpinsMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }
  if (isOpeningScenePlaying) {
    return false;
  }
  if (isSpinShortCut) {
    return false;
  }
  if (isSnowCorrect) {
    return false;
  }

  return true;
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isBuyFeatureEnabled = (features: ClientSlotFeatureSettings[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const isDemo = urlSearchParams.has('isDemo');

export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

export const hasRespinBonus = (): boolean => {
  const freeSpinsBonus = getFreeSpinBonus();
  if (freeSpinsBonus) {
    const isBonus = getBonusKind(freeSpinsBonus.bonus!.id);
    return isBonus === BonusKind.RESPIN_BASE || isBonus === BonusKind.RESPIN_FREE_SPIN;
  }
  return false;
};

export const hasFreeSpinBonus = (): boolean => {
  const freeSpinsBonus = getFreeSpinBonus();
  if (freeSpinsBonus) {
    const isBonus = getBonusKind(freeSpinsBonus.bonus!.id);
    return isBonus === BonusKind.FREE_SPINS;
  }
  return false;
};

export const hasWin = (nextResult: ISettledBet | null): boolean => {
  if (!nextResult) return false;

  const totalAmount = nextResult.paylines.reduce((acc, payline) => {
    if (payline && payline.amount !== null) {
      return acc + payline.amount;
    } else {
      return acc;
    }
  }, 0);

  return totalAmount > 0;
};

export const checkNonBonusAutoSpinStop = (nextResult: ISettledBet | null): boolean => {
  if (setIsAutoSpins()) {
    const balanceAmount = nextResult ? nextResult.balance.settled.amount : 0;
    const autoSpinsLeft = setAutoSpinsLeft() <= 0;
    const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

    const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

    const balanceIncrease =
      setIsStopOnBalanceIncrease() &&
      balanceAmount &&
      setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

    const balanceDecrease =
      setIsStopOnBalanceDecrease() &&
      balanceAmount &&
      setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

    if (autoSpinsLeft || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
      return true;
    }
  }
  return false;
};
