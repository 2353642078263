import * as PIXI from 'pixi.js';

import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { layerBackGround } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

export type BgType = 'default' | 'freeSpin01' | 'freeSpin02' | 'freeSpin03';

const BgTypeToAnimation: Record<BgType, string> = {
  default: 'base',
  freeSpin01: 'a',
  freeSpin02: 'b',
  freeSpin03: 'c',
};

class Background extends PIXI.Container {
  private base: TickerSpine<'bg_basegame'> = new TickerSpine('bg_basegame');

  private freeSpin: TickerSpine<'bg_fs'> = new TickerSpine('bg_fs');

  private currentBgType: BgType;

  constructor(_bgType: BgType = 'default') {
    super();

    this.addChild(this.base, this.freeSpin);

    this.base.state.setAnimation(0, 'base', true);
    this.freeSpin.state.setEmptyAnimation(0);

    this.currentBgType = 'default';
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    this.scale.set(1);

    this.parentLayer = layerBackGround;
  }

  private onChangeMode(settings: { mode: GameMode; bgType?: BgType }) {
    const { bgType } = settings;
    if (bgType === undefined) return;

    if (this.currentBgType != bgType) {
      this.currentBgType = bgType;
      if (bgType === 'default') {
        this.freeSpin.state.setEmptyAnimation(0);
      } else {
        this.freeSpin.state.setAnimation(0, BgTypeToAnimation[bgType] as SpineInterface['bg_fs']['animations'], true);
      }
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const size = { width: 2000, height: 2000 };
    const bgAspectRatio = size.width / size.height;
    const aspectRatio = width / height;

    let scale = 1.0;
    if (bgAspectRatio > aspectRatio) {
      scale = height / size.height;
    } else {
      scale = width / size.width;
    }
    this.base.scale.set(scale);
    this.freeSpin.scale.set(scale);
  }
}

export default Background;
