import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { isFreeSpinsGameMode } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { layerReelFrameFront } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, SNOW_COUNTUP_ANIM_DURATION, SNOW_COUNT_MAX, eventManager } from '../config';
import { Icon } from '../d';

import { SNOW_METER_PORTRAIT_POSITION, SNOW_METER_POSITION } from './config';
import { SnowParticleContainer } from './particle/snowParticleContainer';

enum SNOW_METER_ANIMATION_TRACK {
  BASE = 0,
  COUNT = 1,
}

class SnowMeter extends ViewContainer {
  private meter: TickerSpine<'snow_counter'>;
  private nowCount = 0;

  private snowParticleContainer: SnowParticleContainer;
  constructor() {
    super();
    this.meter = new TickerSpine<'snow_counter'>('snow_counter');
    this.meter.state.setAnimation(0, 'frame_base', true);
    this.meter.position.copyFrom(SNOW_METER_POSITION);
    this.addChild(this.meter);

    this.snowParticleContainer = new SnowParticleContainer();
    this.addChild(this.snowParticleContainer);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.SET_FREESPINS_SNOW_COUNT, this.setSnowCount.bind(this));
    eventManager.addListener(EventTypes.SNOW_COUNT_FORCE_UPDATE, this.forceUpdateSnowCount.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.visible = false;
    this.parentLayer = layerReelFrameFront;
  }

  private setSnowCount(snowCount: number | undefined) {
    this.nowCount = snowCount ? snowCount : 0;

    if (this.nowCount === 0) {
      this.resetSnowAnimation();
    }
  }

  private forceUpdateSnowCount() {
    const count = this.nowCount > SNOW_COUNT_MAX ? SNOW_COUNT_MAX : this.nowCount;
    if (count === 0) {
      this.resetSnowAnimation();
    } else {
      const snowWaitAnim = `snow_0${count}_wait` as SpineInterface['snow_counter']['animations'];
      this.meter.state.setAnimation(SNOW_METER_ANIMATION_TRACK.COUNT, snowWaitAnim, true);
      this.checkMaxAnimation();
    }
  }

  private resetSnowCount() {
    this.nowCount = 0;
    this.resetSnowAnimation();
  }

  private resetSnowAnimation() {
    this.meter.state.setAnimation(SNOW_METER_ANIMATION_TRACK.BASE, 'frame_base', true);
    this.meter.state.setEmptyAnimation(SNOW_METER_ANIMATION_TRACK.COUNT);
  }
  private playSnowCountAnimation(count: number) {
    const snowInAnim = `snow_0${count}_in` as SpineInterface['snow_counter']['animations'];
    const snowWaitAnim = `snow_0${count}_wait` as SpineInterface['snow_counter']['animations'];
    this.meter.state.setAnimation(SNOW_METER_ANIMATION_TRACK.COUNT, snowInAnim, false);
    this.meter.state.addAnimation(SNOW_METER_ANIMATION_TRACK.COUNT, snowWaitAnim, true, 0);

    this.checkMaxAnimation();
  }

  private checkMaxAnimation() {
    if (this.nowCount >= SNOW_COUNT_MAX) {
      this.meter.state.setAnimation(SNOW_METER_ANIMATION_TRACK.BASE, 'frame_max', true);
      this.meter.state.addAnimation(SNOW_METER_ANIMATION_TRACK.COUNT, 'snow_max_wait', true);
      AudioApi.play({ type: ISongs.XT004S_snow_max, stopPrev: true });
    }
  }

  private addSnowCount() {
    if (this.nowCount < SNOW_COUNT_MAX) {
      this.setSnowCount(this.nowCount + 1);
      this.playSnowCountAnimation(this.nowCount);
    }
    if (this.nowCount < SNOW_COUNT_MAX) {
      const addSound = `XT004S_snow_add_${this.nowCount}`;
      AudioApi.play({ type: addSound, stopPrev: true });
    } else {
      AudioApi.play({ type: ISongs.XT004S_snow_max, stopPrev: true });
    }
  }
  //no use, but keep
  public getSnowCountUpAnimation(snowCount: number | undefined): AnimationChain {
    const target = snowCount ? (snowCount > SNOW_COUNT_MAX ? SNOW_COUNT_MAX : snowCount) : 0;
    const animationChain = new AnimationChain();
    const upCount = target - this.nowCount;
    const delay = SNOW_COUNTUP_ANIM_DURATION / upCount;

    for (let i = this.nowCount + 1; i <= target; i++) {
      const countDelay = Tween.createDelayAnimation(delay);
      countDelay.addOnStart(() => {
        this.setSnowCount(i);
        this.playSnowCountAnimation(this.nowCount);
      });
      animationChain.appendAnimation(countDelay);
    }

    animationChain.addOnComplete(() => {
      this.setSnowCount(target);
    });
    return animationChain;
  }

  public getSnowCollectAnimation(spinResult: Icon[]): AnimationGroup {
    return this.snowParticleContainer.getSnowCollectAnimation(
      spinResult,
      this.nowCount,
      this.meter.y,
      this.addSnowCount.bind(this),
    );
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    if (isFreeSpinsGameMode(settings.mode)) {
      this.visible = true;
    } else {
      this.visible = false;
      this.resetSnowCount();
    }
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.meter.position.copyFrom(SNOW_METER_POSITION);
    } else {
      this.meter.position.copyFrom(SNOW_METER_PORTRAIT_POSITION);
    }
  };
}

export default SnowMeter;
