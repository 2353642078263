import { upgradeConfig } from '@pixi/particle-emitter';
import * as PIXI from 'pixi.js';
import { IPointData } from 'pixi.js';

import { easeInBack } from '../../../utils';
import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { ParticleEmitterContainer } from '../../components/particles';

import { snowParticleConfig } from './config';

export class SnowParticle extends ParticleEmitterContainer {
  constructor() {
    super(
      upgradeConfig(
        snowParticleConfig,
        PIXI.Loader.shared.resources['message']!.spineAtlas!.findRegion('message_obj_02').texture,
      ),
    );
    this.stop(true);
  }

  public getTrackAnimation(start: IPointData, end: IPointData, duration: number, cb?: () => void): AnimationGroup {
    const xTween = new Tween({
      object: this.emitter.spawnPos,
      property: TweenProperties.X,
      propertyBeginValue: start.x,
      target: end.x,
      easing: easeInBack,
      duration,
    });
    const yTween = new Tween({
      object: this.emitter.spawnPos,
      property: TweenProperties.Y,
      propertyBeginValue: start.y,
      target: end.y,
      easing: easeInBack,
      duration,
    });
    const anim = new AnimationGroup();
    anim.addAnimation(xTween);
    anim.addAnimation(yTween);
    anim.addOnStart(() => {
      this.stop(true);
      this.start();
    });
    anim.addOnComplete(() => {
      if (anim) {
        this.stop();
        cb?.();
      }
    });

    return anim;
  }
  public skip() {
    this.stop(true);
  }
}
