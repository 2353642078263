export enum BonusKind {
  RESPIN_BASE = 'RespinBase',
  FREE_SPINS = 'FreeSpins',

  RESPIN_FREE_SPIN = 'RespinFreeSpin',
  BUY_FEATURE = 'BuyFeature',
}

export type RespinBaseBonusInfo = {
  bonusKind: BonusKind.RESPIN_BASE;
};

export type FreespinBonusInfo = {
  bonusKind: BonusKind.FREE_SPINS;
};

export type RespinFreeSpinBonusInfo = {
  bonusKind: BonusKind.RESPIN_FREE_SPIN;
};

export type BuyFeatureBonusInfo = {
  bonusKind: BonusKind.BUY_FEATURE;
};

//TO DO
export const BonusDataByBonusId: Record<
  string,
  RespinBaseBonusInfo | FreespinBonusInfo | RespinFreeSpinBonusInfo | BuyFeatureBonusInfo
> = {
  'f43bb43d-79d3-4d49-aad7-b395a1e4fc2a': {
    bonusKind: BonusKind.FREE_SPINS,
  },
  'f59f8234-43c6-4b23-a32c-76d69533eecd': {
    bonusKind: BonusKind.RESPIN_BASE,
  },
  'cb6c12fe-f6e3-4954-b219-f390d3897f67': {
    bonusKind: BonusKind.RESPIN_FREE_SPIN,
  },
  '2322f0dd-ed02-4b7c-a884-da67fbfd96e4': {
    bonusKind: BonusKind.BUY_FEATURE,
  },
};
