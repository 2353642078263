import { setIsAutoSpins, setIsBuyFeaturePopupOpened, setIsBuyFeaturePurchased } from '../gql/cache';
import { EventTypes, eventManager } from '../slotMachine/config';

export function fallBackReelPosition() {
  if (setIsBuyFeaturePopupOpened() || setIsBuyFeaturePurchased()) {
    eventManager.emit(EventTypes.BUY_FEATURE_CONFIRMED);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  } else {
    window.dispatchEvent(new CustomEvent('placeBetCompleted'));
    eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
    if (setIsAutoSpins()) setIsAutoSpins(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  }
}
