import { Colors } from '../../config/variables';
import { GameMode, PopupOpeningTypes } from '../../consts';
import {
  setBrokenGame,
  setCurrentBonus,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import { calcPercentage, getGameModeByBonusId, isFreeSpinsMode } from '../../utils';
import Tween from '../animations/tween';
import { UiButton } from '../components/ui/uiButton';
import { EventTypes, eventManager } from '../config';

export class BetBtn extends UiButton {
  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private isReSpinsWin = false;
  constructor() {
    super('bet');
    this.interactive = !this.isDisabled;

    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : GameMode.REGULAR;
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;

    this.btn.tint = Colors.GAME_COLOR;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean, isContinueAutoSpinsAfterFeature: boolean) => {
      this.isAutoSpins = isAutoSpins || isContinueAutoSpinsAfterFeature;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_RESPINS_WIN, (isReSpinWin: boolean) => {
      this.isReSpinsWin = isReSpinWin;
      this.handleDisable();
    });
  };

  private handleDisable = (): void => {
    this.setDisable(
      isFreeSpinsMode(this.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isFreeSpinsWin ||
        this.isSlotBusy ||
        this.isReSpinsWin ||
        setIsBuyFeaturePopupOpened() ||
        setIsContinueAutoSpinsAfterFeature(),
    );
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BET_SETTINGS_POPUP);

      setIsOpenBetSettingsPopup(!setIsOpenBetSettingsPopup());
      setIsOpenAutoplayPopup(false);
      setIsOpenInfoPopup(false);
      setIsOpenHistoryPopup(false);
      setIsBuyFeaturePopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      Tween.setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 1);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height - gap;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      x = this.applicationSize.width - gap;
      y = this.applicationSize.height - gap;
      this.setSize(size);
    }

    // if (this.isLandscapeMode) {}

    this.x = x;
    this.y = y;
  }
}
