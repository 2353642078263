import * as PIXI from 'pixi.js';

import { GameMode } from '../../consts';
import { ISettledBet } from '../../global.d';
import {
  setBetAmount,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsStopOnFeatureWin,
  setNextResult,
  setUserLastBetResult,
} from '../../gql/cache';
import i18n from '../../i18next';
import { hasWin, isBaseGameMode, isFreeSpinsBaseMode, isFreeSpinsMode, isRespinMode } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { layerMessageWindow } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import {
  BASE_WIN_AMOUNT_LIMIT,
  EventTypes,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
  messageWindowAutoResizeTextStyles,
} from '../config';
import { IWinLine } from '../d';
import { SNOW_LEVEL_MULTIPLIER } from '../snow/config';

class MessageWindow extends PIXI.Container {
  private frame: TickerSpine<'message_frame'>;

  private spinCounter: number;

  private messageText: AutoResizeText;

  private snowLevel: number;

  private preGameMode: GameMode = GameMode.REGULAR;

  constructor() {
    super();

    this.frame = new TickerSpine('message_frame');
    this.frame.state.setAnimation(0, 'base');
    this.frame.visible = true;
    this.spinCounter = 0;

    this.messageText = this.initMessageText();

    this.snowLevel = 0;

    this.addChild(this.frame, this.messageText);

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.REELS_STOP_ANIMATION_COMPLETE, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));

    eventManager.addListener(EventTypes.HIDE_WIN_LINES, this.hideLine.bind(this));
    eventManager.addListener(EventTypes.SHOW_WIN_LINES, this.showLine.bind(this));

    eventManager.addListener(EventTypes.SET_FREESPINS_SNOW_LEVEL, (snowLevel?: number) => {
      this.snowLevel = snowLevel ?? 0;
    });

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.onWinMessageBanner.bind(this));
    eventManager.addListener(EventTypes.CREATE_LEVELUP_BANNER, this.onLevelUpMessageBanner.bind(this));

    eventManager.addListener(EventTypes.SET_IS_IN_TRANSITION, this.transitionChange.bind(this));
    eventManager.addListener(EventTypes.START_SNOW_COLLECT_ANIMATION, this.onSnowCollectAnimation.bind(this));
    eventManager.addListener(EventTypes.START_FS_WIN_ANIMATION, this.showFreeSpinWin.bind(this));

    eventManager.addListener(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.messageText.visible = false;
    });
    eventManager.addListener(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.messageText.visible = true;
    });

    eventManager.addListener(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      if (isFreeSpinsBaseMode(setGameMode())) {
        return;
      }
      if (!isAutoSpins) this.messageText.visible = true;
    });

    this.x = SLOTS_CONTAINER_WIDTH / 2;
    this.y = 837 - 102 + 122 / 2;

    this.parentLayer = layerMessageWindow;
  }

  private initMessageText(): AutoResizeText {
    const messageText = new AutoResizeText('', messageWindowAutoResizeTextStyles);
    messageText.x = 0;
    messageText.y = 9;
    messageText.anchor.set(0.5);
    /*
    messageText.style.fill = messageText.context.createPattern(
      PIXI.Loader.shared.resources[ResourceTypes.textPatternMessageFrame]!.data,
      'repeat',
    )!;
    */
    return messageText;
  }

  private updateText(text: string) {
    this.messageText.text = text;
    if (!setIsBuyFeaturePopupOpened()) {
      this.messageText.visible = true;
    }
  }

  private transitionChange(isInTransition: boolean) {
    if (!isInTransition && isBaseGameMode(setGameMode()) && !setIsAutoSpins() && setBrokenBuyFeatureGame() === '') {
      this.updateText(i18n.t('messageWindow.idol'));
    }
  }

  private onStartSpin() {
    if (isRespinMode(setGameMode())) {
      this.updateText(i18n.t('messageWindow.respin'));
    } else if (isFreeSpinsBaseMode(setGameMode()) || setGameMode() === GameMode.FREE_SPINS_RESPIN) {
      this.updateText(
        i18n.t('messageWindow.fs_remain', {
          round: setCurrentBonus().roundsPlayed + 1,
          total: setCurrentBonus().rounds,
        }),
      );
    } else {
      const messages: string[] = i18n.t('messageWindow.spin', { returnObjects: true });
      this.updateText(messages[this.spinCounter]!);
      this.spinCounter = (this.spinCounter + 1) % messages.length;
    }
  }

  private onReelStopped() {
    if (!isFreeSpinsMode(setGameMode()) && !hasWin(setNextResult())) {
      this.updateText(i18n.t('messageWindow.idol'));
      if (setIsAutoSpins()) {
        this.messageText.visible = false;
      }
    }
  }

  private onStartWinAnimation(_nextResult: ISettledBet): void {
    if (isFreeSpinsBaseMode(setGameMode()) || setGameMode() === GameMode.FREE_SPINS_RESPIN) {
      //this.updateText(i18n.t('messageWindow.win'));
    } else {
      this.updateText(i18n.t('messageWindow.win'));
    }
  }

  private showLine(lines: IWinLine[]): void {
    if (isFreeSpinsBaseMode(setGameMode()) || setGameMode() === GameMode.FREE_SPINS_RESPIN) {
      if (lines.some((line) => line.lineId !== null)) {
        this.updateText(
          i18n.t('messageWindow.fs_win', {
            levelMultiplier: SNOW_LEVEL_MULTIPLIER[this.snowLevel], //TO DO
          }),
        );
      } else {
        this.updateText(i18n.t('messageWindow.win'));
      }
    }
  }

  private showFreeSpinWin() {
    this.updateText(i18n.t('messageWindow.win'));
  }

  private hideLine() {
    /*if (isFreeSpinsBaseMode(setGameMode()) || setGameMode() === GameMode.FREE_SPINS_RESPIN) {
      this.updateText('');
    }*/
  }

  private onModeChange(settings: { mode: GameMode }) {
    const message = isRespinMode(settings.mode)
      ? 'messageWindow.respin'
      : isFreeSpinsMode(settings.mode)
      ? 'messageWindow.trigger'
      : 'messageWindow.idol';
    this.updateText(i18n.t(message));
    if (
      (!isFreeSpinsBaseMode(settings.mode) && setIsAutoSpins()) ||
      (isFreeSpinsBaseMode(settings.mode) &&
        setBrokenGame() &&
        setUserLastBetResult()?.data.features.gameRoundStore.snowMeter)
    ) {
      this.messageText.visible = false;
    }

    const isAutoSkipTitle =
      isFreeSpinsBaseMode(settings.mode) && setIsContinueAutoSpinsAfterFeature() && !setIsStopOnFeatureWin()
        ? true
        : false;
    if (isAutoSkipTitle) {
      this.messageText.visible = false;
    }

    if (settings.mode === GameMode.FREE_SPINS && this.preGameMode === GameMode.FREE_SPINS_RESPIN) {
      this.messageText.visible = false;
    }

    this.preGameMode = settings.mode;
  }

  private onWinMessageBanner() {
    const multiplier = setFreeSpinsTotalWin() / setBetAmount();
    const message = multiplier >= BASE_WIN_AMOUNT_LIMIT ? 'messageWindow.rsend2' : 'messageWindow.rsend1';

    this.updateText(i18n.t(message));
  }

  private onLevelUpMessageBanner() {
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.messageText.visible = false;
    } else {
      this.updateText(i18n.t('messageWindow.fs_lvup'));
    }
  }

  private onSnowCollectAnimation(snowCount: number) {
    if (snowCount === 0) return;

    const message =
      snowCount >= 4
        ? 'messageWindow.fs_heart3'
        : snowCount >= 2
        ? 'messageWindow.fs_heart2'
        : 'messageWindow.fs_heart1';

    this.updateText(i18n.t(message));
  }
}

export default MessageWindow;
