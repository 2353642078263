import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import ViewContainer from '../../components/container';
import { layerReelFrame } from '../../components/layers/layers';
import { TickerSpine } from '../../components/spine';
import {
  EventTypes,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_REELFRAME_HEIGHT,
  SLOTS_REELFRAME_WIDTH,
  SLOTS_REELFRAME_X,
  SLOTS_REELFRAME_Y,
  eventManager,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private frame: TickerSpine<'reelframe'>;

  private freeze: TickerSpine<'re_ice'>;
  constructor() {
    super();
    this.frame = new TickerSpine('reelframe');
    this.frame.position.set(
      SLOTS_REELFRAME_X + SLOTS_REELFRAME_WIDTH / 2,
      SLOTS_REELFRAME_Y + SLOTS_REELFRAME_HEIGHT / 2,
    );
    this.frame.state.setAnimation(0, 'base', true);
    this.addChild(this.frame);

    this.freeze = new TickerSpine<'re_ice'>('re_ice');
    this.freeze.x = SLOTS_CONTAINER_WIDTH / 2;
    this.freeze.y = SLOTS_CONTAINER_HEIGHT / 2;
    this.addChild(this.freeze);
    this.freeze.visible = false;

    eventManager.addListener(EventTypes.START_RESPIN_FREEZE_ANIMATION, this.startFreezeAnimation.bind(this));
    eventManager.addListener(EventTypes.END_RESPIN_FREEZE_ANIMATION, this.endFreezeAnimation.bind(this));

    this.parentLayer = layerReelFrame;
  }

  private startFreezeAnimation() {
    this.freeze.visible = true;
    this.freeze.state.setAnimation(0, 'in', false);
    this.freeze.state.addAnimation(0, 'loop', true);
    AudioApi.play({
      type: ISongs.XT004S_freeze,
      stopPrev: true,
    });
  }
  private endFreezeAnimation() {
    const animation = this.freeze.getAnimation(0, 'out');
    animation.addOnComplete(() => {
      this.freeze.visible = false;
    });
    animation.start();
    AudioApi.play({
      type: ISongs.XT004S_freeze_break,
      stopPrev: true,
    });
  }
}

export default ReelsFrame;
