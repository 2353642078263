import * as PIXI from 'pixi.js';

import { SlotId } from '../../../config';
import { lowerCaseSymbol } from '../../../global.d';
import Animation from '../../animations/animation';
import { layerReel } from '../../components/layers/layers';
import { AnimationStateListener, TickerSpine } from '../../components/spine';
import { SLOT_SCALE } from '../../config';

import { IAnimateSlot } from './IAnimateSlot';

declare global {
  interface String {
    toLowerCase<T extends string>(this: T): Lowercase<T>;
  }
}

export class SpineAnimateSlot extends TickerSpine<`symbol_${lowerCaseSymbol}`> implements IAnimateSlot {
  public slotId: SlotId;

  private longspinAnimationListener: AnimationStateListener;

  constructor(slotId: SlotId) {
    super(`symbol_${slotId.toLowerCase()}`);

    this.slotId = slotId;
    this.scale.set(SLOT_SCALE);
    this.state.addAnimation(0, 'wait', true);
    this.setBaseZIndex();
    this.parentLayer = layerReel;

    this.longspinAnimationListener = {
      complete: (entry) => {
        if (entry.animation?.name === 'longspin') {
          //console.log('track ' + entry.trackIndex + ' completed ' + entry.loopsCount() + ' times');
        }
      },
    };
  }

  public startSpinStartAnimation(): void {
    this.state.setAnimation(0, 'spin', false);
    this.state.addAnimation(0, 'spining', true);
    this.setBaseZIndex();
  }

  public startStopAnimation(): void {
    this.state.setAnimation(0, 'stop', false);
    this.state.addAnimation(0, 'wait', true);
    this.state.update(0);
    this.setBaseZIndex();
  }

  public startLongSpinStopAnimation(): void {
    this.state.setAnimation(0, 'longspin_stop', false);
    this.state.addAnimation(0, 'longspin', true);
  }

  public startWinAnimation(): void {
    this.state.setAnimation(0, 'win', false);
    this.state.addAnimation(0, 'wait', true);
  }

  public startFSWinAnimation(): void {
    this.state.setAnimation(0, 'win', true);
    //    this.state.addAnimation(0, 'wait', true);
  }

  public startLongspinAnimation(): void {
    this.state.setAnimation(0, 'longspin', true);
    this.state.addListener(this.longspinAnimationListener);
  }

  public setSpinningAnimation(): void {
    this.state.setAnimation(0, 'spining', true);
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration = this.skeleton.data.findAnimation('win').duration * 1000;

    const listener: AnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.state.addListener(listener);
        this.setWinZIndex();
      });
    });
    animation.addOnSkip(() => {
      this.state.removeListener(listener);
      this.state.addAnimation(0, 'wait', true);
      this.setBaseZIndex();
    });
    animation.addOnComplete(() => {
      this.state.removeListener(listener);
      this.state.addAnimation(0, 'wait', true);
      this.setBaseZIndex();
    });

    return animation;
  }

  private setBaseZIndex() {}

  private setWinZIndex() {}

  public skip(): void {
    this.state.addAnimation(0, 'wait', true);
  }

  public forceStop(): void {
    this.state.setAnimation(0, 'wait', true);
  }

  public manualUpdate() {
    this.update(0);
  }
}
