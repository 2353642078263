import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SlotMachine from '../../slotMachine';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });

  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');

  useEffect(() => {
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins[
      'extract'
    ] as PIXI.Extract;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().buyFeatureBtn?.btnContainer!));
  }, []);

  const featuresFeature: string[] = t('FeatureDesc', {
    returnObjects: true,
  });

  const featuresRespin: string[] = t('FeatureRespinDesc', {
    returnObjects: true,
  });
  const featuresBonus: string[] = t('FeatureBonusDesc', {
    returnObjects: true,
  });

  const featuresFreesSpin1: string[] = t('FeatureFreeSpinDesc1', {
    returnObjects: true,
  });
  const featuresFreesSpin3: string[] = t('FeatureFreeSpinDesc3', {
    returnObjects: true,
  });

  const featuresBuyAFeature: string[] = t('FeatureBuyAFeatureDesc1', {
    returnObjects: true,
  });

  const featureFreeSpinDesc = t('FeatureFreeSpinDesc2', {
    returnObjects: true,
    level1: 3,
    level2: 5,
    level3: 10,
    level4: 30,
    level5: 50,
  });

  return (
    <section>
      <h1 className={styles.subtitle}>{t('FeatureSubTitle')}</h1>
      <div className={`${styles.p}`}>
        {featuresFeature.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles.subtitle}>{t('FeatureRespinSubTitle')}</h1>
      <div className={`${styles.p}`}>
        {featuresRespin.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles.subtitle}>{t('FeatureBonusSubTitle')}</h1>
      <div className={`${styles.p}`}>
        {featuresBonus.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles.subtitle}>{t('FeatureFreeSpinSubTitle')}</h1>
      <div className={`${styles.p}`}>
        {featuresFreesSpin1.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <div className={`${styles.p} ${styles.center}`}>
        {featureFreeSpinDesc.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <div className={`${styles.p}`}>
        {featuresFreesSpin3.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles.subtitle}>{t('FeatureBuyAFeatureTitle')}</h1>
      <div className={styles.featureIcon}>
        <img draggable="false" src={infoBuyFeatureIcon} />
      </div>
      <div className={`${styles.p}`}>
        {featuresBuyAFeature.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });

  return (
    <section>
      <h1 className={styles.title}>{t('FeatureTitle')}</h1>
      <FreeSpinFeature />
    </section>
  );
};

export default Features;
