import { captureException } from '@sentry/react';
import _filter from 'lodash/filter';
import * as PIXI from 'pixi.js';

import { config } from '../config';
import { SpineInterface } from '../config/spine.generated';

import { wait } from './timer';

const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((_loader, resources) => {
      const failed = _filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
  });
};
export const loadPixiAssets = async (assets: PIXI.IAddOptions[], baseUrl: string): Promise<void> => {
  PIXI.Loader.shared.baseUrl = baseUrl;

  PIXI.Loader.shared.add(assets);
  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      await pixiLoad();
      success = true;
      break;
    } catch (failed) {
      if (failed instanceof Error) {
        captureException(failed);
      } else {
        captureException(new Error(`loadPixiAssets: ${JSON.stringify(failed)}`));

        const retryResources = (failed as PIXI.LoaderResource[]).map(({ name }) => {
          if (PIXI.Loader.shared.resources[name]) {
            const res = PIXI.Loader.shared.resources[name] as PIXI.LoaderResource;

            // from PIXI.Loader.reset()
            if (res._onLoadBinding) {
              (res._onLoadBinding as { detach: () => boolean }).detach();
            }
            if (res.isLoading) {
              res.abort('loader reset');
            }

            delete PIXI.Loader.shared.resources[name];
          }
          return assets.find((v) => v.name === name)!;
        });
        PIXI.Loader.shared.add(retryResources);
        await wait(1000);
      }
    }
  }

  if (success === false) {
    throw new Error();
  }
};

export const addTextureCacheFromSpineAtlas = (prefix: string) => {
  const set = new Set();
  for (const resource in PIXI.Loader.shared.resources) {
    const atlas = PIXI.Loader.shared.resources[resource]!.spineAtlas;
    if (atlas) {
      for (const region of atlas.regions) {
        if (region.name.includes('/')) continue; // ignore sequential assets
        if (set.has(region.name)) continue;

        PIXI.Texture.addToCache(region.texture, `${prefix}${region.name}`);
        set.add(region.name);
      }
    }
  }
};

export const getAnimationDurationFromSpine = (
  spineName: keyof SpineInterface,
  animationName: SpineInterface[keyof SpineInterface]['animations'],
): number => {
  return PIXI.Loader.shared.resources[spineName]!.spineData?.findAnimation(animationName).duration! * 1000 ?? 0;
};
