import AudioApi from '@phoenix7dev/audio-api';

import { SpineInterface } from '../../../config/spine.generated';
import { AnticipationType } from '../../../global';
import ViewContainer from '../../components/container';
import { layerReelBack, layerReelFront } from '../../components/layers/layers';
import {
  EventTypes,
  REEL_WIDTH,
  SLOTS_REELFRAME_HEIGHT,
  SLOTS_REELFRAME_WIDTH,
  SLOTS_REELFRAME_X,
  SLOTS_REELFRAME_Y,
  SLOT_HEIGHT,
  SLOT_RESOURCE_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../../config';

import { ISongs } from './../../../config/audio/sprite.generated';
import { TickerSpine } from './../../components/spine/index';

class ReelsBackgroundContainer extends ViewContainer {
  private bgSpine: TickerSpine<'reelframe'>;

  private longspinReelFrameBack: TickerSpine<'longspin'>;

  private longspinReelFrameFront: TickerSpine<'longspin'>;

  constructor() {
    super();

    this.bgSpine = new TickerSpine('reelframe');
    this.bgSpine.position.set(
      SLOTS_REELFRAME_X + SLOTS_REELFRAME_WIDTH / 2,
      SLOTS_REELFRAME_Y + SLOTS_REELFRAME_HEIGHT / 2,
    );
    this.addChild(this.bgSpine);

    this.bgSpine.state.setAnimation(0, 'back', true);
    //    this.bgSpine.stateData.setMix('base', 'longspin', 0.5);

    this.longspinReelFrameBack = new TickerSpine<'longspin'>('longspin');
    this.longspinReelFrameBack.x = REEL_WIDTH * 4 + SLOT_WIDTH / 2;
    this.longspinReelFrameBack.y = SLOT_HEIGHT * 1 + SLOT_RESOURCE_HEIGHT / 2;
    this.longspinReelFrameBack.visible = false;
    this.addChild(this.longspinReelFrameBack);
    this.longspinReelFrameBack.parentLayer = layerReelBack;

    this.longspinReelFrameFront = new TickerSpine<'longspin'>('longspin');
    this.longspinReelFrameFront.x = REEL_WIDTH * 4 + SLOT_WIDTH / 2;
    this.longspinReelFrameFront.y = SLOT_HEIGHT * 1 + SLOT_RESOURCE_HEIGHT / 2;
    this.longspinReelFrameFront.visible = false;
    this.addChild(this.longspinReelFrameFront);
    this.longspinReelFrameFront.parentLayer = layerReelFront;

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    //    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.REELS_STOP_ANIMATION_COMPLETE, this.onAnticipationAnimationEnd.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_REELFRAME_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.onAnticipationAnimationEnd.bind(this));

    eventManager.addListener(EventTypes.START_CUTIN_ANIMATION, this.startReelFrameAnimation.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));

    //    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupCutinAnimation.bind(this));

    this.parentLayer = layerReelBack;
  }

  private onModeChange(): void {}

  private onStartSpin(): void {}

  private onReelStopped() {}

  private setLongSpinFrameVisible(value: boolean) {
    this.longspinReelFrameBack.visible = value;
    this.longspinReelFrameFront.visible = value;
  }

  private longSpinFrameAnimationReset() {
    this.longspinReelFrameBack.state.setEmptyAnimation(0);
    this.longspinReelFrameBack.state.setEmptyAnimation(1);

    this.longspinReelFrameFront.state.setEmptyAnimation(0);
    this.longspinReelFrameFront.state.setEmptyAnimation(1);
  }
  private forceStopReels(_isTurboSpin: boolean, allowForceReelStop?: boolean) {
    if (allowForceReelStop) {
      this.setLongSpinFrameVisible(false);
      this.longSpinFrameAnimationReset();
    }
  }

  private startReelFrameAnimation() {}

  private onAnticipationAnimationStarts(reelIdx: number, type: AnticipationType, durationType: number): void {
    const DurationToString = ['s', 'm', 'l'];
    const anticipationTypeName = type === 'Bonus' || type === 'BonusSnow' ? 'bonus' : 'snow';

    if (type != 'None') {
      const backAnimationName =
        `${anticipationTypeName}_${DurationToString[durationType]}_back` as SpineInterface['longspin']['animations'];
      const frontAnimationName =
        `${anticipationTypeName}_${DurationToString[durationType]}_front` as SpineInterface['longspin']['animations'];

      this.setLongSpinFrameVisible(true);
      this.longspinReelFrameBack.state.setAnimation(0, backAnimationName);
      this.longspinReelFrameFront.state.setAnimation(0, frontAnimationName);

      this.longspinReelFrameBack.x = REEL_WIDTH * reelIdx + SLOT_WIDTH / 2;
      this.longspinReelFrameFront.x = REEL_WIDTH * reelIdx + SLOT_WIDTH / 2;

      const AudioDurationToString = ['short', 'middle', 'long'];
      const AudioSlotType = type === 'Bonus' || type === 'BonusSnow' ? 'SC2' : 'SC1';

      const audioName = `XT004S_${AudioSlotType}_longspin_${AudioDurationToString[durationType]}`;
      AudioApi.play({ type: audioName, stopPrev: true });
    }
  }

  private onAnticipationAnimationEnd(): void {
    this.setLongSpinFrameVisible(false);
    this.longSpinFrameAnimationReset();
    AudioApi.stop({ type: ISongs.XT004S_SC1_longspin_short, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC1_longspin_middle, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC1_longspin_long, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC2_longspin_short, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC2_longspin_middle, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC2_longspin_long, stopPrev: true });
  }
}

export default ReelsBackgroundContainer;
