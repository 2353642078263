import { Combo } from '../gql/__generated__/graphql';
import { setSlotConfig } from '../gql/cache';
import client from '../gql/client';
import { slotBetGql } from '../gql/query';

import { getNonNullableValue } from '.';

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key!]: value,
    };
  }, {});
  return param as T;
};

export const filterCombos = (combos: Combo[], iconId: string): Combo[] => {
  return combos.filter((combo) => {
    const patternNumber = parseInt(combo.pattern.slice(1));
    return patternNumber >= 3 && patternNumber <= 9 && !(iconId === 'SC2' && patternNumber !== 3);
  });
};

export const getLastRegularBetReelPositions = async (betId: string): Promise<number[]> => {
  const bet = await client.query({
    query: slotBetGql,
    variables: { input: { id: betId } },
    fetchPolicy: 'network-only',
  });
  if (bet.data.bet?.userBonus) {
    if (bet.data.bet?.data.features.gameRoundStore.buyFeatureStore) {
      return getNonNullableValue(bet.data.bet?.data.features.gameRoundStore.buyFeatureStore.reelPositionsOverride);
    } else {
      return await getLastRegularBetReelPositions(bet.data.bet?.userBonus.betId!);
    }
  } else if (bet.data.bet?.result) {
    return getNonNullableValue(bet.data.bet.result.reelPositions);
  }
  return getNonNullableValue(setSlotConfig()!.settings?.startPosition);
};
