import { EmitterConfigV1 } from '@pixi/particle-emitter';

export const snowParticleConfig: EmitterConfigV1 = {
  pos: {
    x: 0,
    y: 0,
  },
  addAtBack: false,

  alpha: {
    start: 1,
    end: 0,
  },
  scale: {
    start: 0.9,
    end: 0.4,
    minimumScaleMultiplier: 0.1,
  },
  /*
  color: {
    start: '#ffffff',
    end: '#ffffff',
  },
  */
  speed: {
    start: 200,
    end: 100,
    minimumSpeedMultiplier: 1,
  },
  acceleration: {
    x: 0,
    y: 0,
  },
  maxSpeed: 0,
  startRotation: {
    min: -5,
    max: 5,
  },
  noRotation: false,
  rotationSpeed: {
    min: -1,
    max: 1,
  },
  lifetime: {
    min: 0.5,
    max: 1.0,
  },
  blendMode: 'add',
  //blendMode: 'soft-light',
  //blendMode: 'hard-mix',
  frequency: 0.01,
  emitterLifetime: -1,
  maxParticles: 80,
  spawnType: 'circle',
  spawnCircle: {
    x: 0,
    y: 0,
    r: 30, // 生成される粒子の半径を指定して分散度を上げる
  },
};
