export const easeInOutQuart = (x: number): number => {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
};

export const easeOutQuart = (x: number): number => {
  return 1 - Math.pow(1 - x, 4);
};

export const easeInSine = (x: number): number => {
  return 1 - Math.cos((x * Math.PI) / 2);
};

export const easeOutQuint = (x: number): number => {
  return 1 - Math.pow(1 - x, 5);
};

export const easeInQuint = (x: number): number => {
  return x * x * x * x * x;
};

export const easeInCubic = (x: number): number => {
  return x * x * x;
};

export function easeInBack(x: number): number {
  const c1 = 1.70158;
  const c3 = c1 + 1;

  return c3 * x * x * x - c1 * x * x;
}
