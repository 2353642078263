export enum GameMode {
  REGULAR,
  REGULAR_RESPIN,
  FREE_SPINS,
  FREE_SPINS_RESPIN,
  BUY_FEATURE,
}

export enum PopupOpeningTypes {
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null as unknown as number,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',

  TURBO_SPIN = 'TURBO_SPIN',
}
