import { MessageFreeSpinsBannerProps } from '../../global';
import i18n from '../../i18next';
import AutoResizeText from '../components/autoResizeText';
import { TickerSpine } from '../components/spine/index';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  FREESPIN_START_BANNER_DESCRIPTION_POS,
  FREESPIN_START_BANNER_ROUND_POS,
  FREESPIN_START_BANNER_TITLE_POS,
} from './config';
import {
  freeSpinStartBannerDescriptionStyles,
  freeSpinStartBannerRoundStyles,
  freeSpinStartBannerTitleStyles,
} from './textStyles';

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinsBannerProps;

  private bg: TickerSpine<'fs_title'>;

  private title: AutoResizeText;
  private rounds: AutoResizeText;
  private description: AutoResizeText;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.props = props;

    this.bg = new TickerSpine<'fs_title'>('fs_title');
    this.bg.update(0);

    this.title = this.initTitle();
    this.rounds = this.initRound();
    this.description = this.initDescription();
  }

  public override init(): MessageFreeSpinsBanner {
    super.init();
    this.bg.state.setAnimation(0, 'base', true);
    this.addChild(this.bg);

    this.addChild(this.title, this.rounds, this.description);
    return this;
  }

  protected initTitle(): AutoResizeText {
    const autoResizeText = new AutoResizeText(i18n.t('freeSpinStartBanner.title'), freeSpinStartBannerTitleStyles);
    autoResizeText.anchor.set(0.5);
    autoResizeText.y = FREESPIN_START_BANNER_TITLE_POS.y;
    return autoResizeText;
  }

  protected initRound(): AutoResizeText {
    const autoResizeText = new AutoResizeText(
      i18n.t('freeSpinStartBanner.rounds', { round: 12 }),
      freeSpinStartBannerRoundStyles,
    );
    autoResizeText.anchor.set(0.5);
    autoResizeText.y = FREESPIN_START_BANNER_ROUND_POS.y;
    return autoResizeText;
  }

  protected initDescription(): AutoResizeText {
    const autoResizeText = new AutoResizeText(
      i18n.t('freeSpinStartBanner.description'),
      freeSpinStartBannerDescriptionStyles,
    );
    autoResizeText.anchor.set(0.5);
    autoResizeText.y = FREESPIN_START_BANNER_DESCRIPTION_POS.y;
    return autoResizeText;
  }

  public override handleResize(width: number, height: number): void {
    super.handleResize(width, height);
    if (height > width) {
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;
    } else {
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;
    }
  }
}
