import { SlotId } from '../../config';
import { AnticipationInfo, ReelSet } from '../../global.d';
import { setForceReelStop, setGameMode } from '../../gql/cache';
import { isBuyFeatureMode, isFreeSpinsMode, isRespinMode } from '../../utils';
import Tween from '../animations/tween';
import { AnnounceReelRollingExternalDuration, AnnounceType } from '../announce/config';
import ViewContainer from '../components/container';
import { layerReel } from '../components/layers/layers';
import {
  ANTICIPATION_BONUS_ENDING_DURATION,
  ANTICIPATION_BONUS_ENDING_SLOTS_AMOUNT,
  ANTICIPATION_REEL_FORMULA,
  ANTICIPATION_SHORT_ROLLING_DURATION,
  ANTICIPATION_SHORT_START_REELID,
  ANTICIPATION_SNOW_ENDING_DURATION,
  ANTICIPATION_SNOW_ENDING_SLOTS_AMOUNT,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ROLLING_SPEED,
  BASE_SPIN_TIME,
  EventTypes,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  REELS_AMOUNT,
  REEL_BUYFEATURE_MYSTERY_ENDING_AMOUNT,
  REEL_BUYFEATURE_MYSTERY_ENDING_DURATION,
  REEL_BUYFEATURE_ROLLING_DURATION,
  REEL_ENDING_SLOTS_AMOUNT,
  RESPIN_REEL_INDEX,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SOUND_REEL_STOP_SOUND_PRIORITY,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_SPIN_ANTICIPATION_SHORT_ROLLING_DURATION,
  TURBO_SPIN_TIME,
  eventManager,
} from '../config';

import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public forcedStop = false;

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.initReels(reels, startPosition);
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, () => {
      this.hideSlots();
    });
    eventManager.addListener(EventTypes.REEL_STOPPED, this.hideSlots.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.showSlots.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReelsBranching.bind(this));
    eventManager.addListener(EventTypes.CHANGE_REEL_SET, this.changeReelSet.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));

    this.parentLayer = layerReel;
  }

  private hideSlots(reelId?: number): void {
    const arr = [];

    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i * REELS_AMOUNT + reelId);
      }
    } else {
      for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i);
      }
    }
    this.setSlotsVisibility(arr, false);
  }

  private showSlots(reelId?: number): void {
    const arr = [];
    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) arr.push(i * REELS_AMOUNT + reelId);
    } else {
      for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) arr.push(i);
    }
    this.setSlotsVisibility(arr, true);
  }

  private rollbackReels(positions: number[]): void {
    for (let i = 0; i < positions.length; i++) {
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getStartingDelay() as Tween);
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getStarting() as Tween);
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getFirstRolling() as Tween);
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getFakeRolling() as Tween);
      this.reels[i]!.position = this.reels[i]!.size - positions[i]!;
      this.reels[i]!.state = ReelState.IDLE;
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;

    //reference point
    this.x = 0;
    this.y = 0;
  }

  private changeReelSet(settings: { reelSet: ReelSet; reelPositions: number[] }): void {
    const reelLayout = settings.reelSet.layout.map((reel) =>
      reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel,
    );

    const reelPositions = settings.reelPositions
      .slice(0, REELS_AMOUNT)
      .map((position, idx) => (reelLayout[idx]!.length - position) % reelLayout[idx]!.length);

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i]!.clean();
      this.reels[i]!.init(reelLayout[i]!, reelPositions[i]!);
    }
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    reels = reels.map((reel) => (reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel));

    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i]! : 0;
      const reel = new Reel(i, reels[i]!, position);
      this.reels[i] = reel;
      this.addChild(reel.container);

      eventManager.emit(EventTypes.REGISTER_ANIMATOR, reel.animator);
    }
  }

  private forceStopReelsBranching(isTurboSpin: boolean, allowForceStop?: boolean): void {
    if (allowForceStop || isFreeSpinsMode(setGameMode())) {
      this.forceStopReels(isTurboSpin);
    }
  }

  private forceStopReels(isTurboSpin: boolean): void {
    this.forcedStop = true;
    setForceReelStop(true);
    const startTime = this.reels[0]!.spinAnimation ? this.reels[0]!.spinAnimation!.startTime : 0;
    const stopAllReelsAtSameTime = Date.now() - startTime < (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);

    if (stopAllReelsAtSameTime) {
      let [maxSoundPrio, maxSoundIdx] = [0, 2];
      for (let i = 0; i < this.reels.length; i++) {
        const prio = SOUND_REEL_STOP_SOUND_PRIORITY[this.reels[i]!.stopSoundType];

        if (prio > maxSoundPrio) {
          [maxSoundPrio, maxSoundIdx] = [prio, i];
        }
        this.reels[i]!.isPlaySoundOnStop = false;
      }
      this.reels[maxSoundIdx]!.isPlaySoundOnStop = true;
    }

    for (let i = 0; i < this.reels.length; i++) {
      if (isRespinMode(setGameMode()) && i === RESPIN_REEL_INDEX) continue;
      this.reels[i]!.stopReel(
        stopAllReelsAtSameTime
          ? FORCE_STOP_SPIN_ANIMATION_DURATION
          : FORCE_STOP_SPIN_ANIMATION_DURATION + i * FORCE_STOP_SPIN_PER_EACH_DURATION,
      );
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setAnimationfromCalculatedTarget(
    rollingAnimation: Tween,
    endingAnimation: Tween,
    target: number,
    endingSlotAmount: number,
    rollingSpeed: number,
    reelIdx: number,
  ): void {
    let beginValue = target - endingSlotAmount - Math.round(rollingAnimation.duration * rollingSpeed);
    if (beginValue < 0) {
      const prolong = this.prolongTarget(this.reels[reelIdx]!, Math.abs(beginValue));
      beginValue += prolong;
      target += prolong;
    }
    rollingAnimation.propertyBeginValue = beginValue;
    rollingAnimation.target = target - endingSlotAmount;

    endingAnimation.propertyBeginValue = target - endingSlotAmount;
    endingAnimation.target = target;
  }

  private setNextReelAnticipationAnimation(anticipationInfo: AnticipationInfo, reelIdx: number, nextReelIdx: number) {
    const nextStopSlotIds =
      anticipationInfo.reels[nextReelIdx]?.type === 'Bonus'
        ? [SlotId.SC2]
        : anticipationInfo.reels[nextReelIdx]?.type === 'Snow'
        ? [SlotId.SC1]
        : [];
    if (nextStopSlotIds.includes(SlotId.SC1) || nextStopSlotIds.includes(SlotId.SC2)) {
      if (!this.forcedStop) {
        eventManager.emit(EventTypes.ANTICIPATION_STOPPED_SLOT_ANIMATIONS_START, nextStopSlotIds, reelIdx);
      }
    }
  }

  private anticipationAnimationStartCheck(
    anticipationInfo: AnticipationInfo,
    reelIdx: number,
    durationType: number,
    isRespin: boolean,
  ) {
    const nextStopSlotIds =
      anticipationInfo.reels[reelIdx]?.type === 'Bonus'
        ? [SlotId.SC2]
        : anticipationInfo.reels[reelIdx]?.type === 'Snow'
        ? [SlotId.SC1]
        : [];
    if (nextStopSlotIds.includes(SlotId.SC1) || nextStopSlotIds.includes(SlotId.SC2)) {
      if (!this.forcedStop) {
        eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_START, nextStopSlotIds, reelIdx, isRespin);
        eventManager.emit(
          EventTypes.ANTICIPATION_REELFRAME_START,
          reelIdx,
          anticipationInfo.reels[reelIdx]?.type!,
          durationType,
        );

        if (isRespin) {
          eventManager.emit(EventTypes.SHOW_TINT, true, 0);
          eventManager.emit(EventTypes.SHOW_TINT, true, 1);
          eventManager.emit(EventTypes.SHOW_TINT, true, 3);
          eventManager.emit(EventTypes.SHOW_TINT, true, 4);
        } else {
          eventManager.emit(EventTypes.SHOW_TINT, true);
        }
      }
    }
  }

  private anticipationAnimationEndCheck(anticipationInfo: AnticipationInfo, reelIdx: number, isRespin: boolean) {
    if (reelIdx != 3) return;
    if (isRespin) return;

    if (anticipationInfo.reels[reelIdx]?.type === 'Bonus' && anticipationInfo.reels[reelIdx + 1]?.type === 'None') {
      eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END);
    } else if (
      anticipationInfo.reels[reelIdx]?.type === 'Bonus' &&
      anticipationInfo.reels[reelIdx + 1]?.type === 'Snow'
    ) {
      eventManager.emit(EventTypes.ANTICIPATION_BONUS_ANIMATIONS_END);
    }
  }

  private setupBaseGameAnimationTarget(
    reelPositions: number[],
    anticipationInfo: AnticipationInfo,
    announceType: AnnounceType,
    isRespin: boolean,
  ): void {
    const rollingSpeed = this.reels[0]!.isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;
    let preAnticipationDuration = 0;

    for (let j = 0; j < this.reels.length; j++) {
      if (isRespin && j === RESPIN_REEL_INDEX) {
        const spinAnimation = this.reels[j]!.spinAnimation;
        if (spinAnimation) {
          spinAnimation.getFakeRolling().duration = 0;
          spinAnimation.getRolling().duration = 0;
          spinAnimation.getEnding().duration = 0;
        }
        continue;
      }
      const fakeRollingAnimation = this.reels[j]!.spinAnimation!.getFakeRolling();
      const rollingAnimation = this.reels[j]!.spinAnimation!.getRolling();
      const endingAnimation = this.reels[j]!.spinAnimation!.getEnding();
      const target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[j]!);
      let anticipationDurationType = 0;

      fakeRollingAnimation.duration = 0;
      this.reels[j]!.stopSoundType = anticipationInfo.reels[j]!.soundType;
      this.reels[j]!.anticipationInfo = anticipationInfo.reels[j]!;

      rollingAnimation.duration += AnnounceReelRollingExternalDuration[announceType];

      if (anticipationInfo.reels[j]?.type === 'Bonus' || anticipationInfo.reels[j]?.type === 'Snow') {
        const endingDurationTbl =
          anticipationInfo.reels[j]?.type === 'Bonus'
            ? ANTICIPATION_BONUS_ENDING_DURATION
            : ANTICIPATION_SNOW_ENDING_DURATION;

        const endingAmoutTbl =
          anticipationInfo.reels[j]?.type === 'Bonus'
            ? ANTICIPATION_BONUS_ENDING_SLOTS_AMOUNT
            : ANTICIPATION_SNOW_ENDING_SLOTS_AMOUNT;

        anticipationDurationType = Math.floor(Math.random() * endingDurationTbl.length);
        const endingSlotAmount = endingAmoutTbl[anticipationDurationType]!;
        const anticipation_duration = endingDurationTbl[anticipationDurationType]!;
        const rolling_adjust_duration =
          this.reels[0]!.isTurboSpin && anticipationInfo.reels[j]?.type === 'Bonus'
            ? TURBO_SPIN_ANTICIPATION_SHORT_ROLLING_DURATION
            : ANTICIPATION_SHORT_ROLLING_DURATION;

        rollingAnimation.duration += preAnticipationDuration;
        rollingAnimation.duration += rolling_adjust_duration;
        this.setAnimationfromCalculatedTarget(
          rollingAnimation,
          endingAnimation,
          target,
          endingSlotAmount,
          rollingSpeed,
          j,
        );
        if (anticipationInfo.reels[j]?.type === 'Bonus' && j === 3) {
          endingAnimation.duration = BASE_REEL_ENDING_DURATION;
        }
        endingAnimation.duration += anticipation_duration;
        endingAnimation.easing = ANTICIPATION_REEL_FORMULA;

        preAnticipationDuration += rolling_adjust_duration + endingAnimation.duration;
      } else {
        rollingAnimation.duration += preAnticipationDuration;
        this.setAnimationfromCalculatedTarget(
          rollingAnimation,
          endingAnimation,
          target,
          REEL_ENDING_SLOTS_AMOUNT,
          rollingSpeed,
          j,
        );
      }

      if (isRespin && j === 0) {
        rollingAnimation.addOnStart(() => {});
      }

      if (isRespin && j === 1) {
        endingAnimation.addOnComplete(() => {
          this.setNextReelAnticipationAnimation(anticipationInfo, j, j + 2);
        });
      } else if (j <= ANTICIPATION_SHORT_START_REELID) {
        endingAnimation.addOnComplete(() => {
          this.setNextReelAnticipationAnimation(anticipationInfo, j, j + 1);
        });
      }
      endingAnimation.addOnStart(() => {
        this.anticipationAnimationStartCheck(anticipationInfo, j, anticipationDurationType, isRespin);
      });
      endingAnimation.addOnComplete(() => {
        eventManager.emit(EventTypes.SET_SNOW_PRIZE_SNOW_COUNT, anticipationInfo.reels[j]?.snowCount!);
        if (j === 3) {
          this.anticipationAnimationEndCheck(anticipationInfo, j, isRespin);
        }
      });
    }
  }

  //TO DO
  private setupBuyFeatureAnimationTarget(reelPositions: number[]): void {
    //    const rollingSpeed = BASE_REEL_ROLLING_SPEED;
    const isTurboSpin = this.reels[0]!.isTurboSpin;
    const rollingSpeed = isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;

    const endingAnimationType = 0;
    const endingSlotAmount = REEL_BUYFEATURE_MYSTERY_ENDING_AMOUNT[endingAnimationType]!;
    const endingAnimationDuration = isTurboSpin
      ? REEL_BUYFEATURE_MYSTERY_ENDING_DURATION[endingAnimationType]! / 2
      : REEL_BUYFEATURE_MYSTERY_ENDING_DURATION[endingAnimationType]!;

    for (let j = 0; j < this.reels.length; j++) {
      const fakeRollingAnimation = this.reels[j]!.spinAnimation!.getFakeRolling();
      const rollingAnimation = this.reels[j]!.spinAnimation!.getRolling();
      const endingAnimation = this.reels[j]!.spinAnimation!.getEnding();
      let target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[j]!);

      fakeRollingAnimation.duration = 0;
      rollingAnimation.duration = this.reels[0]!.isTurboSpin
        ? REEL_BUYFEATURE_ROLLING_DURATION / 2
        : REEL_BUYFEATURE_ROLLING_DURATION;

      let beginValue = target - endingSlotAmount - Math.round(rollingAnimation.duration * rollingSpeed);
      if (beginValue < 0) {
        const prolong = this.prolongTarget(this.reels[j]!, Math.abs(beginValue));
        beginValue += prolong;
        target += prolong;
      }
      rollingAnimation.propertyBeginValue = beginValue;
      rollingAnimation.target = target - endingSlotAmount;

      endingAnimation.duration += endingAnimationDuration;
      endingAnimation.propertyBeginValue = target - endingSlotAmount;
      endingAnimation.target = target;
      endingAnimation.addOnComplete(() => {});
    }
  }

  private setupAnimationTarget(
    reelPositions: number[],
    anticipationInfo: AnticipationInfo,
    announceType: AnnounceType,
  ): void {
    eventManager.emit(EventTypes.START_ANNOUNCEMENT, announceType);

    if (announceType === 'phoenix') {
      eventManager.emit(EventTypes.PHOENIX_START);
    }

    if (isBuyFeatureMode()) {
      this.setupBuyFeatureAnimationTarget(reelPositions);
    } else {
      this.setupBaseGameAnimationTarget(reelPositions, anticipationInfo, announceType, isRespinMode(setGameMode()));
    }
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const reel = this.reels[x];
      if (!reel) return;

      const position = reel.size - (Math.round(reel.position) % reel.size) + y - 1;
      const normalizedPosition = position === -1 ? reel.size - 1 : position % reel.size;
      const slot = reel.slots[normalizedPosition];
      if (slot) slot.visible = visibility;
    });
  }
}

export default ReelsContainer;
