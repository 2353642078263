import { IAddOptions, LoaderResource } from 'pixi.js';
import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';
import { ENABLE_MERGED_ATRAS, preloadSpineTextures, spineFile } from './spine.generated';

export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.SC1]: ResourceTypes.symbolSc1,
  [SlotId.SC2]: ResourceTypes.symbolSc2,
  [SlotId.WL]: ResourceTypes.symbolWl,
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
};

export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.SC1]: ResourceTypes.symbolSc1,
  [SlotId.SC2]: ResourceTypes.symbolSc2,
  [SlotId.WL]: ResourceTypes.symbolWlBlur,
  [SlotId.A]: ResourceTypes.symbolABlur,
  [SlotId.B]: ResourceTypes.symbolBBlur,
  [SlotId.C]: ResourceTypes.symbolCBlur,
  [SlotId.D]: ResourceTypes.symbolDBlur,
  [SlotId.E]: ResourceTypes.symbolEBlur,
  [SlotId.F]: ResourceTypes.symbolFBlur,
  [SlotId.G]: ResourceTypes.symbolGBlur,
};

export const MAPPED_ENDING_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.SC1]: ResourceTypes.symbolSc1,
  [SlotId.SC2]: ResourceTypes.symbolSc2,
  [SlotId.WL]: ResourceTypes.symbolWl,
  [SlotId.A]: ResourceTypes.symbolAStop,
  [SlotId.B]: ResourceTypes.symbolBStop,
  [SlotId.C]: ResourceTypes.symbolCStop,
  [SlotId.D]: ResourceTypes.symbolDStop,
  [SlotId.E]: ResourceTypes.symbolEStop,
  [SlotId.F]: ResourceTypes.symbolFStop,
  [SlotId.G]: ResourceTypes.symbolGStop,
};
export const LOADER_TEXTURES: IAddOptions[] = Object.values(ResourceTypes).map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const SPINE_ATLASES: IAddOptions[] = ENABLE_MERGED_ATRAS
  ? [
      {
        name: 'spine_atlas',
        url: '/animations/desktop/spine/merge.atlas',
        loadType: LoaderResource.LOAD_TYPE.XHR,
        xhrType: LoaderResource.XHR_RESPONSE_TYPE.TEXT,
      },
    ]
  : [];

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};

export const SPINE_LOADER_TEXTURES = (_isMobile: boolean): IAddOptions[] => {
  return [
    // {
    //   name: 'coinsAnimation',
    //   url: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    // },
    ...spineFile.map((name) => ({
      name,
      url: generateTexturePath('/animations', `spine/${name}.json`),
      metadata: ENABLE_MERGED_ATRAS
        ? {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data,
            imageNamePrefix: 'spine_',
          }
        : {},
    })),
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'buy_bottonSprite',
    url: '/images/pixijs/snow_pixi.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
  {
    name: 'ui',
    url: '/images/ui/ui.json',
  },
];

export const PRELOAD_SPINE_TEXTURES: IAddOptions[] = preloadSpineTextures.map((v) => {
  return { name: `spine_${v}`, url: `/animations/desktop/spine/${v}` };
});
