import * as PIXI from 'pixi.js';

import ViewContainer from '../components/container';
import { REELS_Z_ORDER_TINT_CONTAINER } from '../components/layers/config';
import { layerReel } from '../components/layers/layers';
import {
  EventTypes,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOT_WIDTH,
  TINT_ALPHA,
  TINT_BACKGROUND_COLOR,
  eventManager,
} from '../config';

class ReelTintContainer extends ViewContainer {
  public tints: PIXI.Graphics[] = [];

  constructor() {
    super();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const tintWidth = i === 0 ? SLOT_WIDTH / 2 + REEL_WIDTH / 2 : REEL_WIDTH;
      const tint = new PIXI.Graphics()
        .beginFill(TINT_BACKGROUND_COLOR, TINT_ALPHA)
        .drawRect(0, 0, tintWidth, SLOTS_CONTAINER_HEIGHT)
        .endFill();
      tint.pivot.set(i === 0 ? SLOT_WIDTH / 2 : REEL_WIDTH / 2, 0);
      tint.x = i * REEL_WIDTH + SLOT_WIDTH / 2;
      tint.visible = false;

      this.addChild(tint);
      this.tints.push(tint);
    }
    eventManager.addListener(EventTypes.SHOW_TINT, this.showTint.bind(this));
    eventManager.on(EventTypes.ANTICIPATION_ANIMATIONS_END, () => {
      this.tints.forEach((tint) => (tint.visible = false));
    });

    this.parentLayer = layerReel;
    this.zOrder = REELS_Z_ORDER_TINT_CONTAINER;
  }

  private showTint(state: boolean, reelId?: number): void {
    if (reelId !== undefined) {
      this.tints[reelId]!.visible = state;
      return;
    }

    this.tints.forEach((tint) => (tint.visible = state));
  }
}

export default ReelTintContainer;
