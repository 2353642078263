import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../config';
import { GameMode } from '../consts';
import Tween from '../slotMachine/animations/tween';
import { BgType } from '../slotMachine/background/background';
import { BgmControl } from '../slotMachine/bgmControl/bgmControl';
import {
  BonusDataByBonusId,
  BonusKind,
  BuyFeatureBonusInfo,
  FreespinBonusInfo,
  RespinBaseBonusInfo,
  RespinFreeSpinBonusInfo,
} from '../slotMachine/config/bonusInfo';
import { SNOW_LEVEL_TO_BG } from '../slotMachine/snow/config';

export const findBonusInfoByBonusId = (
  id: string | undefined,
): RespinBaseBonusInfo | FreespinBonusInfo | RespinFreeSpinBonusInfo | BuyFeatureBonusInfo | undefined => {
  if (id) {
    return BonusDataByBonusId[id];
  } else {
    return undefined;
  }
};

export const isBuyFeatureTypeByBonusId = (id: string): boolean => {
  const bonusInfo = findBonusInfoByBonusId(id);
  if (bonusInfo?.bonusKind === BonusKind.BUY_FEATURE) {
    return true;
  }
  return false;
};

export const getBgTypeForGameMode = (gamemode: GameMode, snowLLevel: number): BgType => {
  if (gamemode === GameMode.FREE_SPINS || gamemode === GameMode.FREE_SPINS_RESPIN) {
    return SNOW_LEVEL_TO_BG[snowLLevel - 1] ?? 'freeSpin01';
  } else {
    return 'default';
  }
};

export const bannerWaitBgmPlay = (waitSound: ISongs, bg: BgType, delay: number) => {
  AudioApi.play({ type: waitSound, stopPrev: true });
  BgmControl.setRestrict(true);
  //const audiodelay = Tween.createDelayAnimation(durationOf(waitSound));
  const audiodelay = Tween.createDelayAnimation(delay);
  audiodelay.addOnComplete(() => {
    BgmControl.setRestrict(false);
    BgmControl.playBgm(bg, true, true);
  });
  audiodelay.start();
};
