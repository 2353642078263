import * as PIXI from 'pixi.js';

import { ISongs, Variables } from '../../config';
import { BgType } from '../background/background';
import { AutoResizeTextStyle } from '../components/autoResizeText';
import {
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
} from '../config';

export const snowPrizeMultiplierTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  trim: true,
  fontWeight: '700',
  align: 'right',
  stroke: '#001F93',
  strokeThickness: 2,
  lineJoin: 'round',
  maxWidth: 285,
};

export const SNOW_LEVEL_MAX = 5;
export const SNOW_PRIZE_BG_WIDTH = 440;
export const SNOW_PRIZE_BG_HEIGHT = 540;

export const SNOW_PRIZE_POSITION_X = -411 + SNOW_PRIZE_BG_WIDTH / 2;
export const SNOW_PRIZE_POSITION_Y = 108 + SNOW_PRIZE_BG_HEIGHT / 2;

export const SNOW_PRIZE_POSITION_PORTRAIT_X = 645 + 38 + SNOW_PRIZE_BG_WIDTH / 2;
export const SNOW_PRIZE_POSITION_PORTRAIT_Y = 28 + SAFE_AREA_PORTRAIT_PIVOT_Y + SNOW_PRIZE_BG_HEIGHT / 2;

const SNOW_PRIZE_MULTIPLIER_RIGHT = 402 - SNOW_PRIZE_BG_WIDTH / 2;
const SNOW_PRIZE_MULTIPLIER_TOP = -SNOW_PRIZE_BG_HEIGHT / 2 + 94;

export type SnowPrizeComboType = 'x3' | 'x4' | 'x5' | 'x6' | 'x7' | 'x8' | 'x9';
export type SnowPrizeLayoutType = {
  style: Partial<AutoResizeTextStyle>;
  position: {
    x: number;
    y: number;
  };
};

export const SNOW_PRIZE_LAYOUT_TBL: Record<SnowPrizeComboType, SnowPrizeLayoutType> = {
  x3: {
    style: { ...snowPrizeMultiplierTextStyle, maxWidth: 230, fontSize: 38 },
    position: { x: SNOW_PRIZE_MULTIPLIER_RIGHT, y: SNOW_PRIZE_MULTIPLIER_TOP + 401 },
  },
  x4: {
    style: { ...snowPrizeMultiplierTextStyle, maxWidth: 230, fontSize: 40 },
    position: { x: SNOW_PRIZE_MULTIPLIER_RIGHT, y: SNOW_PRIZE_MULTIPLIER_TOP + 343 },
  },
  x5: {
    style: { ...snowPrizeMultiplierTextStyle, maxWidth: 250, fontSize: 42 },
    position: { x: SNOW_PRIZE_MULTIPLIER_RIGHT, y: SNOW_PRIZE_MULTIPLIER_TOP + 281 },
  },
  x6: {
    style: { ...snowPrizeMultiplierTextStyle, maxWidth: 250, fontSize: 44 },
    position: { x: SNOW_PRIZE_MULTIPLIER_RIGHT, y: SNOW_PRIZE_MULTIPLIER_TOP + 216 },
  },
  x7: {
    style: { ...snowPrizeMultiplierTextStyle, maxWidth: 270, fontSize: 46 },
    position: { x: SNOW_PRIZE_MULTIPLIER_RIGHT, y: SNOW_PRIZE_MULTIPLIER_TOP + 150 },
  },
  x8: {
    style: { ...snowPrizeMultiplierTextStyle, maxWidth: 280, fontSize: 48 },
    position: { x: SNOW_PRIZE_MULTIPLIER_RIGHT, y: SNOW_PRIZE_MULTIPLIER_TOP + 80 },
  },
  x9: {
    style: { ...snowPrizeMultiplierTextStyle, maxWidth: 280, fontSize: 50 },
    position: { x: SNOW_PRIZE_MULTIPLIER_RIGHT, y: SNOW_PRIZE_MULTIPLIER_TOP },
  },
};

//SNOW LEVEL
export const levelTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 60,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: ['#0037F9', '#4FFFF4'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fillGradientStops: [0, 1],
  fontWeight: '700',
  align: 'right',
  stroke: '#ffffff',
  strokeThickness: 10,
  lineJoin: 'round',
  maxWidth: 275,
};

export const levelFixedTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 40,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#25387C',
  fontWeight: '700',
  align: 'left',
  stroke: '#ffffff',
  strokeThickness: 8,
  lineJoin: 'round',
  maxWidth: 80,
};
export const descTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 36,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#001950',
  fontWeight: '700',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 8,
  lineJoin: 'round',
  lineHeight: 42,
  maxWidth: 366,
};

export const SNOW_LEVEL_POSITION_X = -132;
export const SNOW_LEVEL_POSITION_Y = 39;
export const SNOW_LEVEL_LANDSCAPE_SCALE = 0.61;

export const SNOW_LEVEL_PORTRAIT_POSITION_X = 433;
export const SNOW_LEVEL_PORTRAIT_POSITION_Y = 106 + 400 / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y;
export const SNOW_LEVEL_PORTRAIT_SCALE = 1.0;

export const SNOW_METER_POSITION = {
  x: 452 + SAFE_AREA_LANDSCAPE_PIVOT_X + 1148 / 2,
  y: -41 + SAFE_AREA_LANDSCAPE_PIVOT_Y + 224 / 2,
};
export const SNOW_METER_PORTRAIT_POSITION = {
  x: 19 + SAFE_AREA_PORTRAIT_PIVOT_X + 1148 / 2,
  y: 512 + SAFE_AREA_PORTRAIT_PIVOT_Y + 224 / 2,
};

export const SNOW_COUNTUP_PARTICLE_DELAY = 200;

export const SNOW_LEVEL_MULTIPLIER = [3, 5, 10, 30, 50];

export const SNOW_LEVEL_TO_BG: BgType[] = [
  'freeSpin01',
  'freeSpin01',
  'freeSpin02',
  'freeSpin02',
  'freeSpin03',
  'freeSpin03',
];

export const SNOW_LEVEL_TO_LEVEL_UP_AUDIO: ISongs[] = [
  ISongs.XT004S_additional_spin_feature1,
  ISongs.XT004S_additional_spin_feature1,
  ISongs.XT004S_additional_spin_feature2,
  ISongs.XT004S_additional_spin_feature2,
  ISongs.XT004S_additional_spin_feature3,
  ISongs.XT004S_additional_spin_feature3,
];
export const SNOW_LEVEL_TO_WAIT_DURATION_AUDIO: number[] = [1714, 1714, 1714, 1714, 3428, 3428];
