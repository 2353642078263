import { GameMode } from '../../consts';
import { isFreeSpinsGameMode } from '../../utils';
import ViewContainer from '../components/container';
import { layerGameTitle } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import {
  EventTypes,
  GAME_TITLE_PORTRAIT_POSITION_X,
  GAME_TITLE_PORTRAIT_POSITION_Y,
  GAME_TITLE_POSITION_X,
  GAME_TITLE_POSITION_Y,
  eventManager,
} from '../config';

class GameTitle extends ViewContainer {
  private title: TickerSpine<'title_logo'>;

  constructor() {
    super();

    this.title = new TickerSpine('title_logo');
    this.title.position.x = GAME_TITLE_POSITION_X;
    this.title.position.y = GAME_TITLE_POSITION_Y;
    this.addChild(this.title);

    this.title.state.setAnimation(0, 'base', true);
    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerGameTitle;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    if (isFreeSpinsGameMode(settings.mode)) {
      this.title.visible = false;
    } else {
      this.title.visible = true;
    }
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.title.state.setAnimation(0, 'base', true);
      this.title.position.x = GAME_TITLE_POSITION_X;
      this.title.position.y = GAME_TITLE_POSITION_Y;
    } else {
      this.title.state.setAnimation(0, 'tate', true);
      this.title.position.x = GAME_TITLE_PORTRAIT_POSITION_X;
      this.title.position.y = GAME_TITLE_PORTRAIT_POSITION_Y;
    }
  };
}

export default GameTitle;
