import TaggedText from 'pixi-tagged-text';
import { Container, IDestroyOptions } from 'pixi.js';

import {
  INTRO_CAROUSEL_HEIGHT,
  INTRO_CAROUSEL_WIDTH,
  INTRO_DOT_POSITION_Y,
  INTRO_FRAME_SPINE_HEIGHT,
  introContents,
} from '../../../config/introContents';
import i18n from '../../../i18next';
import { TweenProperties } from '../../../slotMachine/animations/d';
import Tween from '../../../slotMachine/animations/tween';
import { calcPercentage } from '../../../utils';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import AutoResizeText from '../../components/autoResizeText';
import { TickerSpine } from '../../components/spine';
import { SAFE_AREA_LANDSCAPE_HEIGHT } from '../../config';

import { Dot } from './Dot';
import { CAROUSEL_ANIMATION_DELAY, CAROUSEL_ANIMATION_DURATION, CAROUSEL_DOTS_GAP, CAROUSEL_DOTS_SIZE } from './config';

class Carousel extends Container {
  private slides: typeof introContents;

  //  private frame: TickerSpine<'introscreen'>;
  //private frame: Spine;

  private slidesContainer: Container;

  private dots: Container<Dot>;

  private slideAnimation: AnimationChain;

  constructor(slides: typeof introContents) {
    super();
    this.slides = slides;

    //this.frame = this.initFrame();
    this.slidesContainer = this.initSlides();
    //this.outerText = this.initOuterText();
    this.dots = this.initDots();
    this.addChild(this.slidesContainer, this.dots);

    this.slideAnimation = this.createSlideAnimation();
    this.slideAnimation.start();
  }

  private initSlides(): Container {
    const slides = new Container();
    this.slides.forEach((slide, index) => {
      const container = new Container();

      if (slide.spine) {
        const imageSpine = new TickerSpine(slide.spine.spineName);
        imageSpine.pivot.set(0, -INTRO_FRAME_SPINE_HEIGHT / 2);
        imageSpine.update(0);
        if (imageSpine.state.hasAnimation(slide.spine.animationName)) {
          imageSpine.state.setAnimation(0, slide.spine.animationName, false);
        }
        container.addChild(imageSpine);
      }

      slide.textInfos.forEach((textInfo) => {
        if (textInfo.textStyle['maxWidth']) {
          const text = new AutoResizeText(i18n.t(textInfo.i18nKey), textInfo.textStyle);
          text.anchor.set(1, 1);
          text.position.copyFrom(textInfo.position);
          container.addChild(text);
        } else if (textInfo.textStyle) {
          const text = new TaggedText(
            i18n.t(textInfo.i18nKey),
            textInfo.textStyle as (typeof TaggedText)['defaultStyles'],
          );
          text.update();
          text.draw();
          text.pivot.x = text.defaultStyle.wordWrapWidth! / 2;
          text.position.copyFrom(textInfo.position);
          container.addChild(text);
        }
      });
      container.alpha = index === 0 ? 1 : 0;
      slides.addChild(container);
    });

    return slides;
  }

  private initDots = (): Container<Dot> => {
    const dotsContainer = new Container<Dot>();
    dotsContainer.name = 'slidesContainer';
    for (let i = 0; i < this.slides.length; i++) {
      const dot = new Dot(`${i + 1}`, i === 0);
      dot.name = `${i}`;
      dot.x = i * (CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_GAP);
      dotsContainer.addChild(dot);
    }
    dotsContainer.x = -dotsContainer.width / 2;
    dotsContainer.y = INTRO_DOT_POSITION_Y;
    return dotsContainer;
  };

  private createSlideAnimation(): AnimationChain {
    const slideAnimation = new AnimationChain({ isLoop: true });

    for (let i = 0; i < this.slides.length; i++) {
      const fadeAnimations = new AnimationGroup();
      const slide = this.slidesContainer.children[i]!;
      fadeAnimations.addAnimation(
        new Tween({
          object: slide,
          property: TweenProperties.ALPHA,
          propertyBeginValue: 1,
          target: 0,
          duration: CAROUSEL_ANIMATION_DURATION,
        }),
      );

      const nextIndex = (i + 1) % this.slides.length;
      const nextSlide = this.slidesContainer.children[nextIndex]!;
      fadeAnimations.addAnimation(
        new Tween({
          object: nextSlide,
          property: TweenProperties.ALPHA,
          propertyBeginValue: 0,
          target: 1,
          duration: CAROUSEL_ANIMATION_DURATION,
        }),
      );

      fadeAnimations.addOnStart(() => {
        this.dots.children[nextIndex]?.setActive(true);
        this.dots.children[i]?.setActive(false);
      });

      slideAnimation.appendAnimation(Tween.createDelayAnimation(CAROUSEL_ANIMATION_DELAY));
      slideAnimation.appendAnimation(fadeAnimations);
    }

    return slideAnimation;
  }

  public setSize(width: number, height: number, bottomGap: number): void {
    if (width >= height) {
      const scale = height / SAFE_AREA_LANDSCAPE_HEIGHT;
      this.scale.set(scale);
      this.y = height - INTRO_CAROUSEL_HEIGHT * scale;
    }
    if (width < height + bottomGap) {
      const carouselWidth = calcPercentage(width, 100);
      //const playButtonHeight = calcPercentage(height, 12.5);

      //const scale = width / SAFE_AREA_PORTRAIT_WIDTH;
      const scale = Math.min(carouselWidth / INTRO_CAROUSEL_WIDTH, calcPercentage(height, 50) / INTRO_CAROUSEL_HEIGHT);
      const carouseHeight = INTRO_CAROUSEL_HEIGHT * scale;

      this.scale.set(scale);
      this.y = height / 2 - (carouseHeight * 2) / 6;
    }

    this.x = width / 2;
  }

  public override destroy(options?: boolean | IDestroyOptions): void {
    this.slideAnimation.skip();
    super.destroy(options);
  }
}
export default Carousel;
