import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { setIsBuyFeaturePurchased } from '../../gql/cache';
import AutoResizeText from '../components/autoResizeText';
import { SpriteButton } from '../components/button';
import { layerBuyFeaturePopUp } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import {
  FEATURE_POPUP_CONFIRM_POSITIONS,
  FEATURE_POPUP_LANDSCAPE_POSITION_X,
  FEATURE_POPUP_LANDSCAPE_POSITION_Y,
  FEATURE_POPUP_PORTRAIT_POSITION_X,
  FEATURE_POPUP_PORTRAIT_POSITION_Y,
  FEATURE_POPUP_SPINE_HEIGHT,
  FEATURE_POPUP_SPINE_WIDTH,
} from './config';
import { buyFeatureConfirmTitleStyle, confirmTotalCostTextStyle, totalCostTextAmountStyle } from './textStyles';

export class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: TickerSpine<'buy_banner'>;

  private titleText: AutoResizeText;

  private totalCostText: AutoResizeText;

  private totalCostValue: AutoResizeText;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  constructor() {
    super();
    this.visible = false;

    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => this.handleClose());
    eventManager.on(EventTypes.BUY_FEATURE_CONFIRMED, () => this.handleClose());
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string, coinAmount: number) => {
      this.okBtn.disable = false;
      this.cancelBtn.disable = false;
      this.handleOpen(totalCost, coinAmount);
    });
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerBuyFeaturePopUp;
  }

  private initPopupBg(): TickerSpine<'buy_banner'> {
    const popupBg = new TickerSpine<'buy_banner'>('buy_banner');
    popupBg.update(0);
    popupBg.state.setAnimation(0, 'base', true);
    popupBg.skeleton.setSkinByName('default');
    popupBg.pivot.x = -FEATURE_POPUP_SPINE_WIDTH / 2;
    popupBg.pivot.y = -FEATURE_POPUP_SPINE_HEIGHT / 2;

    return popupBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t('buyFeature.confirm.title'), buyFeatureConfirmTitleStyle);
    title.position.copyFrom(FEATURE_POPUP_CONFIRM_POSITIONS.TITLE);
    title.anchor.set(0.5, 0);

    return title;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t('buyFeature.totalCost'), confirmTotalCostTextStyle);
    totalCostText.position.copyFrom(FEATURE_POPUP_CONFIRM_POSITIONS.TOTAL_COST_TITLE);
    totalCostText.anchor.set(0.5, 0);

    return totalCostText;
  }

  private initTotalCostValue(): AutoResizeText {
    const totalCostValue = new AutoResizeText('0', totalCostTextAmountStyle);
    totalCostValue.anchor.set(0.5, 0);
    totalCostValue.position.copyFrom(FEATURE_POPUP_CONFIRM_POSITIONS.TOTAL_COST_VALUE);

    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: 'buyfeature_btn_cancel',
        hover: 'buyfeature_btn_cancel_over',
        press: 'buyfeature_btn_cancel_click',
        disable: 'buyfeature_btn_cancel_disable',
      },
      () => {
        this.okBtn.disable = false;
        this.cancelBtn.disable = false;
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
    );
    cancelBtn.position.copyFrom(FEATURE_POPUP_CONFIRM_POSITIONS.CANCEL_BUTTON);
    cancelBtn.anchor.set(0.5);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: 'buyfeature_btn_ok',
        hover: 'buyfeature_btn_ok_over',
        press: 'buyfeature_btn_ok_click',
        disable: 'buyfeature_btn_ok_disable',
      },
      () => {
        this.okBtn.disable = false;
        this.cancelBtn.disable = false;
        this.okBtn.interactive = false;
        this.cancelBtn.interactive = false;
        AudioApi.play({ type: ISongs.SFX_UI_General });
        eventManager.emit(EventTypes.HANDLE_BUY_BONUS, 0);
        setIsBuyFeaturePurchased(true);
      },
    );
    okBtn.position.copyFrom(FEATURE_POPUP_CONFIRM_POSITIONS.OK_BUTTON);
    okBtn.anchor.set(0.5);

    return okBtn;
  }

  public handleOpen(totalCost: string, _coinAmount: number): void {
    this.visible = true;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
    this.totalCostValue.text = totalCost;
  }

  public handleClose(): void {
    this.visible = false;
    this.okBtn._trackedPointers = {};
  }

  private applicationResize = (width: number, height: number): void => {
    this.handleResize(width, height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = FEATURE_POPUP_PORTRAIT_POSITION_X;
      this.y = FEATURE_POPUP_PORTRAIT_POSITION_Y;
    } else {
      this.x = FEATURE_POPUP_LANDSCAPE_POSITION_X;
      this.y = FEATURE_POPUP_LANDSCAPE_POSITION_Y;
    }
  }
}
