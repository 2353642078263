import * as PIXI from 'pixi.js';
import { IPointData } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../../config';
import { setIsSnowCorrect } from '../../../gql/cache';
import { easeInBack } from '../../../utils';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { TickerSpine } from '../../components/spine';
import {
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOT_HEIGHT,
  SLOT_RESOURCE_HEIGHT,
  SLOT_WIDTH,
  SNOW_COLLECT_ANIM_DURATION,
  SNOW_COUNT_MAX,
} from '../../config';
import { Icon } from '../../d';
import { SNOW_COUNTUP_PARTICLE_DELAY } from '../config';

import { SnowParticle } from './snowParticle';

export class SnowParticleContainer extends PIXI.Container {
  private particles: SnowParticle[] = [];

  private heart: TickerSpine<'symbol_sc1_heart'>[] = [];

  constructor() {
    super();
    for (let i = 0; i < SNOW_COUNT_MAX; i++) {
      const particle = new SnowParticle();
      this.particles.push(particle);
      this.addChild(particle);

      const heart = new TickerSpine<'symbol_sc1_heart'>('symbol_sc1_heart');
      this.heart.push(heart);
      this.addChild(heart);
    }

    this.visible = false;
  }
  public getSnowCollectAnimation(
    spinResult: Icon[],
    nowCount: number,
    targetY: number,
    cb: () => void,
  ): AnimationGroup {
    const snowCollectAnimtionGroup = new AnimationGroup();

    if (nowCount >= SNOW_COUNT_MAX) nowCount = 8;

    let stopCount = 0;
    this.visible = true;

    const sortedArr: { icon: Icon; index: number }[] = [];
    for (let i = 0; i < REELS_AMOUNT; i++) {
      for (let j = i; j < spinResult.length; j += REELS_AMOUNT) {
        sortedArr.push({ icon: spinResult[j]!, index: j });
      }
    }

    sortedArr.forEach((arr) => {
      const posX = arr.index % REELS_AMOUNT;
      const posY = Math.floor(arr.index / REELS_AMOUNT);
      const icon = arr.icon;
      if (icon.id === SlotId.SC1) {
        const snowCollectAnimtionChain = new AnimationChain();

        const heartSpine = this.heart[stopCount]!;
        heartSpine.position.set(SLOT_WIDTH / 2 + REEL_WIDTH * posX, SLOT_RESOURCE_HEIGHT / 2 + SLOT_HEIGHT * posY);

        snowCollectAnimtionChain.addOnStart(() => {
          heartSpine.state.setAnimation(0, 'base');
        });
        snowCollectAnimtionChain.appendAnimation(Tween.createDelayAnimation(SNOW_COUNTUP_PARTICLE_DELAY * stopCount));

        const heartSpineAnimation = Tween.createDelayAnimation(
          (heartSpine.spineData.findAnimation('base').duration ?? 0) * 1000,
        );
        snowCollectAnimtionChain.appendAnimation(heartSpineAnimation);

        const heartMoveAnimationGroup = new AnimationGroup();
        heartMoveAnimationGroup.addAnimation(
          this.particles[stopCount]?.getTrackAnimation(
            { x: SLOT_WIDTH / 2 + REEL_WIDTH * posX, y: SLOT_RESOURCE_HEIGHT / 2 + SLOT_HEIGHT * posY },
            { x: 212 + nowCount * 104, y: targetY },
            SNOW_COLLECT_ANIM_DURATION,
            cb,
          )!,
        );
        heartMoveAnimationGroup.addAnimation(
          this.getHeartTransAnimation(
            heartSpine,
            { x: SLOT_WIDTH / 2 + REEL_WIDTH * posX, y: SLOT_RESOURCE_HEIGHT / 2 + SLOT_HEIGHT * posY },
            { x: 212 + nowCount * 104, y: targetY },
            SNOW_COLLECT_ANIM_DURATION,
            cb,
          ),
        );
        heartMoveAnimationGroup.addOnStart(() => {
          if (!AudioApi.isPlaying(ISongs.XT004S_snow_particle)) {
            AudioApi.play({ type: ISongs.XT004S_snow_particle, stopPrev: true });
          }
        });
        heartMoveAnimationGroup.addOnComplete(() => {
          heartSpine.state.setEmptyAnimation(0);
        });
        snowCollectAnimtionChain.appendAnimation(heartMoveAnimationGroup);
        snowCollectAnimtionGroup.addAnimation(snowCollectAnimtionChain);
        if (stopCount < SNOW_COUNT_MAX - 1) {
          stopCount++;
        }
        if (nowCount < SNOW_COUNT_MAX - 1) {
          nowCount++;
        }
        snowCollectAnimtionChain.addOnComplete(() => {});
      }
    });
    snowCollectAnimtionGroup.addOnComplete(() => {
      setIsSnowCorrect(false);
      this.setVisible(false);
      this.heart.forEach((heart) => {
        heart.state.setEmptyAnimation(0);
      });
    });
    return snowCollectAnimtionGroup;
  }

  private getHeartTransAnimation(
    obj: TickerSpine,
    start: IPointData,
    end: IPointData,
    duration: number,
    _cb?: () => void,
  ): AnimationGroup {
    const xTween = new Tween({
      object: obj.position,
      property: TweenProperties.X,
      propertyBeginValue: start.x,
      target: end.x,
      easing: easeInBack,
      duration,
    });
    const yTween = new Tween({
      object: obj.position,
      property: TweenProperties.Y,
      propertyBeginValue: start.y,
      target: end.y,
      easing: easeInBack,
      duration,
    });
    const anim = new AnimationGroup();
    anim.addAnimation(xTween);
    anim.addAnimation(yTween);
    anim.addOnStart(() => {});
    anim.addOnComplete(() => {});

    return anim;
  }

  public setVisible(visible: boolean) {
    this.visible = visible;
  }
}
