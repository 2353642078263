import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import i18n from '../../i18next';
import { isFreeSpinsGameMode } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerReelFrameFront } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';
import { IWinLine } from '../d';

import {
  SNOW_LEVEL_LANDSCAPE_SCALE,
  SNOW_LEVEL_MAX,
  SNOW_LEVEL_MULTIPLIER,
  SNOW_LEVEL_PORTRAIT_POSITION_X,
  SNOW_LEVEL_PORTRAIT_POSITION_Y,
  SNOW_LEVEL_PORTRAIT_SCALE,
  SNOW_LEVEL_POSITION_X,
  SNOW_LEVEL_POSITION_Y,
  descTextStyle,
  levelFixedTextStyle,
  levelTextStyle,
} from './config';

class SnowLevel extends ViewContainer {
  private bg: TickerSpine<'mplevel'>;

  private multiplier: TickerSpine<'mplevel'>;
  private levelText: AutoResizeText;

  private descText: AutoResizeText;
  //private multiplierText: AutoResizeText;

  private level = 0;

  constructor() {
    super();

    this.bg = new TickerSpine<'mplevel'>('mplevel');
    this.bg.state.setAnimation(0, 'tate', true);
    this.addChild(this.bg);

    this.levelText = new AutoResizeText(i18n.t('snowLevel.level', { now: 0 }), {
      ...levelTextStyle,
    });
    this.levelText.x = 95;
    this.levelText.y = -43;
    this.levelText.anchor.set(1, 1);
    this.addChild(this.levelText);

    const fixedText = new AutoResizeText('5', {
      ...levelFixedTextStyle,
    });
    fixedText.x = 95;
    fixedText.y = -48;
    fixedText.anchor.set(0, 1);
    this.addChild(fixedText);

    this.descText = new AutoResizeText(i18n.t('snowLevel.description'), {
      ...descTextStyle,
    });
    this.descText.x = 0;
    this.descText.y = -3;
    this.descText.anchor.set(0.5, 0.5);
    this.addChild(this.descText);

    this.multiplier = new TickerSpine<'mplevel'>('mplevel');
    this.multiplier.state.setAnimation(0, 'num_wait', true);
    this.addChild(this.multiplier);

    this.updateLevelText();

    this.visible = false;
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.SET_FREESPINS_SNOW_LEVEL, this.setSnowLevel.bind(this));

    //eventManager.addListener(EventTypes.HIDE_WIN_LINES, this.stopWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_WIN_LINES, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.stopWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.stopWinAnimation.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.stopWinAnimation.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerReelFrameFront;
  }

  private stopWinAnimation() {
    this.multiplier.state.setAnimation(0, 'num_wait');
  }

  private setSnowLevel(level: number | undefined) {
    if (level) {
      this.level = level > SNOW_LEVEL_MAX ? SNOW_LEVEL_MAX - 1 : level;
    } else {
      this.level = 0;
    }
    this.updateLevelText();
  }
  private resetSnowCount() {
    this.level = 0;
    this.updateLevelText();
  }

  private updateLevelText() {
    this.levelText.text = i18n.t('snowLevel.level', { now: this.level + 1 });

    //this.multiplierText.text = `x${SNOW_LEVEL_MULTIPLIER[this.level]}`;

    const skinName = `num_${SNOW_LEVEL_MULTIPLIER[this.level]}` as SpineInterface['mplevel']['skins'];
    this.multiplier.skeleton.setSkinByName(skinName);

    this.multiplier.state.setAnimation(0, 'num_wait');
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    if (isFreeSpinsGameMode(settings.mode)) {
      this.visible = true;
      this.multiplier.state.setAnimation(0, 'num_wait');
    } else {
      this.visible = false;
      this.resetSnowCount();
    }
  }

  private onStartWinAnimation(lines: IWinLine[]): void {
    if (lines.some((line) => line.lineId !== null)) {
      this.multiplier.state.setAnimation(0, 'num_win', true);
    } else {
      this.multiplier.state.setAnimation(0, 'num_wait');
    }
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.x = SNOW_LEVEL_POSITION_X;
      this.y = SNOW_LEVEL_POSITION_Y;
      this.scale.set(SNOW_LEVEL_LANDSCAPE_SCALE);
    } else {
      this.x = SNOW_LEVEL_PORTRAIT_POSITION_X;
      this.y = SNOW_LEVEL_PORTRAIT_POSITION_Y;
      this.scale.set(SNOW_LEVEL_PORTRAIT_SCALE);
    }
  };
}

export default SnowLevel;
